const SET_ID = 'scratch-gui/connection-modal/setId';
const SET_COMM_MODE = 'scratch-gui/connection-modal/setCommMode';

const initialState = {  
    extensionId: null,
    // 默认是 ble 进行通讯，需要 usb 自己在连接窗口进行切换
    isCommBle: true
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    if(!action)
        return state;
    switch (action.type) {
        case SET_ID:
            return Object.assign({}, state, {
                extensionId: action.extensionId
            });
        case SET_COMM_MODE:
            return Object.assign({}, state, {
                isCommBle: action.isCommBle
            });
        default:
            return state;
    }
};

const setConnectionModalExtensionId = function (extensionId) {
    return {
        type: SET_ID,
        extensionId: extensionId
    };
};

const setCommMode = function (isCommBle) {
    return {
        type: SET_COMM_MODE,
        isCommBle: isCommBle
    };
};

export {
    reducer as default,
    initialState as connectionModalInitialState,
    setConnectionModalExtensionId,
    setCommMode
};
