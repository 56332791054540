import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import { connect } from 'react-redux';
import { closePreferences } from '../../reducers/modals';
import bindAll from 'lodash.bindall'; // 绑定所有的通用方法
import Modal from '../../containers/modal.jsx';   // 窗口
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    Button, Col, ConfigProvider, Footer, Layout, message, Row, Switch} from 'antd';
import styles from './preferences-modal.css';
import {
    preferencesModalChangeUselink
} from '../../reducers/preferences-modal';
import enUS from 'antd/es/locale/en_US.js';
import zhCN from 'antd/es/locale/zh_CN.js';
import jaJP from 'antd/es/locale/ja_JP.js';
import ukUA from 'antd/es/locale/uk_UA.js';

class PreferencesModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'getLocale',
            'handleClose',
            'onChangeUselink'
        ]);
        this.state = {
            locale: enUS
        };
    }
    getLocale(){
        switch(document.documentElement.lang.substring(0,2)){
            case 'zh':
                return zhCN;
            case 'ja':
                return jaJP;
            case 'uk':
                return ukUA;
            default:
                return enUS;
        }
    }
    handleClose() {
        //保存
        const ul=this.props.useLink;
        this.props.vm.runtime.useLink=ul;
        if(window.electronAPI){
            if(!window.preferences){
                return;
            }
            window.preferences.value.useLink=ul;
            ScratchGui.props.vm.runtime.useLink = ul;
            window.electronAPI.send("set-preferences", window.preferences);
        }else{
            window.CookieManager.setCookie('useLink',ul);
        }

        this.props.onRequestClose();
    }
    onChangeUselink(checked, event){
        this.props.onPreferencesModalChangeUselink();
    }
    render() {
        if (!this.props.visible) return null;
        const { Content,Footer } = Layout;
        return (<Modal
                    className={styles.modalContent}
                    contentLabel={<FormattedMessage
                        defaultMessage="Preferences"
                        id="gui.menuBar.preferences"
                    />}
                    onRequestClose={this.handleClose}
                >
                    <ConfigProvider locale={this.getLocale()}>
                        <Layout>
                            <Content>
                                <Row>
                                    <Col type="flex" justify="center" align="middle" span={8} style={{
                                        lineHeight: '100px',
                                        paddingRight: '1rem',
                                        textAlign: 'right'
                                    }}>
                                        <Switch checked={this.props.useLink} onChange={this.onChangeUselink} />
                                    </Col>
                                    <Col span={16} style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        lineHeight: '100px',
                                    }}>
                                        <FormattedMessage
                                            defaultMessage="Connect by Makerzoid Link"
                                            id="gui.preferences.useLink"
                                        />
                                    </Col>
                                </Row>
                            </Content>
                            <Footer style={{
                                backgroundColor: 'white'
                            }}>
                                <Row style={{
                                    textAlign: 'center'
                                }}>
                                    <Button type="primary" onClick={this.handleClose}>
                                        <FormattedMessage
                                            defaultMessage="OK"
                                            id="gui.prompt.ok"
                                        />
                                    </Button>
                                </Row>
                            </Footer>
                        </Layout>
                    </ConfigProvider>
                </Modal>
        );
    }
}

PreferencesModal.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    useLink: state.scratchGui.preferencesModal.useLink
});

const mapDispatchToProps = dispatch => ({
    onPreferencesModalChangeUselink: (status) => {
        dispatch(preferencesModalChangeUselink(status));
    },
    onRequestClose: () => {
        dispatch(closePreferences()); // 关闭当前弹窗
    }
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PreferencesModal));
