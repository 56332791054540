import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {connect} from 'react-redux';

import ControlsComponent from '../components/extension-status/extension-control.jsx';

import {openConnectionModal} from '../reducers/modals';
import {setConnectionModalExtensionId} from '../reducers/connection-modal';

// import {
//     updateDeviceStatus
// } from '../reducers/cards';

// import { 
//     fileManagementAddExtension,
//     fileManagementReload,
//     fileManagementResponse,
//     fileManagementUpload
// } from '../reducers/file-manager';

class Controls extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleExtensionAdded',
            'handleBlocksInfoUpdate',
            'handleStatusButtonUpdate',
            'handleStatusButtonClick'

            // ,'handleDeviceStatusUpdate'
            // ,'handleFileManagementAddExtension'
            // ,'handleFileManagementReload'
            // ,'handleFileManagementResponse'
            // ,'handleFileManagementUpload'
        ]);
        this.state = {
            items: []
        };
    }

    attachVM () {
        this.props.vm.addListener('EXTENSION_ADDED', this.handleExtensionAdded);
        this.props.vm.addListener('BLOCKSINFO_UPDATE', this.handleBlocksInfoUpdate);
        this.props.vm.addListener('PERIPHERAL_CONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.addListener('PERIPHERAL_DISCONNECTED', this.handleStatusButtonUpdate);
        // this.props.vm.addListener('DEVICE_STATUS_UPDATE', this.handleDeviceStatusUpdate);
        // this.props.vm.addListener('FILE_MANAGEMENT_ADD_EXTENSION', this.handleFileManagementAddExtension);
        // this.props.vm.addListener('FILE_MANAGEMENT_RELOAD', this.handleFileManagementReload);
        // this.props.vm.addListener('FILE_MANAGEMENT_RESPONSE', this.handleFileManagementResponse);
        // this.props.vm.addListener('FILE_MANAGEMENT_UPLOAD', this.handleFileManagementUpload);
    }
    detachVM () {
        this.props.vm.removeListener('EXTENSION_ADDED', this.handleExtensionAdded);
        this.props.vm.removeListener('BLOCKSINFO_UPDATE', this.handleBlocksInfoUpdate);
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleStatusButtonUpdate);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.handleStatusButtonUpdate);
        // this.props.vm.removeListener('DEVICE_STATUS_UPDATE', this.handleDeviceStatusUpdate);
        // this.props.vm.removeListener('FILE_MANAGEMENT_ADD_EXTENSION', this.handleFileManagementAddExtension);
        // this.props.vm.removeListener('FILE_MANAGEMENT_RELOAD', this.handleFileManagementReload);
        // this.props.vm.removeListener('FILE_MANAGEMENT_RESPONSE', this.handleFileManagementResponse);
        // this.props.vm.removeListener('FILE_MANAGEMENT_UPLOAD', this.handleFileManagementUpload);
    }

    handleExtensionAdded (categoryInfo) {
        let old = this.state.items.find((item, index)=>{
            return item.id == categoryInfo.id;
        });
        if(old != null){
            return;
        }
        var item = {id: categoryInfo.id, blockIconURI: categoryInfo.blockIconURI}
        this.setState({items: this.state.items.concat(item)});
    }

    handleBlocksInfoUpdate (categoryInfo) {
        // @todo Later we should replace this to avoid all the warnings from redefining blocks.
        this.handleExtensionAdded(categoryInfo);
    }

    handleStatusButtonClick (id) {
        if(id){
            this.props.onOpenConnectionModal(id);
        }
    }

    handleStatusButtonUpdate () {
        this.forceUpdate();
    }

    componentDidMount () {
        this.attachVM()
    }

    componentWillUnmount () {
        this.detachVM();
    }

    handleDeviceStatusUpdate(statusInfo){
        this.props.onUpdateDeviceStatus(statusInfo);
    }

    /**
     * 处理文件管理-添加扩展的事件
     * @param {*} extension 
     */
    handleFileManagementAddExtension(extension){
        this.props.onFileManagementAddExtension(extension)
    }

    handleFileManagementReload(flag){
        this.props.onFileManagementReload(flag);
    }

    handleFileManagementResponse(response){
        this.props.onFileManagementResponse(response);
    }

    handleFileManagementUpload(uploading){
        this.props.onFileManagementUpload(uploading);
    }

    render () {
        return (
            <ControlsComponent
                items = {this.state.items}
                onStatusClick = {this.handleStatusButtonClick}
                {...this.props}
            />
        );
    }
}

Controls.propTypes = {
    vm: PropTypes.instanceOf(VM),
    pathToMedia: PropTypes.string,
    onOpenConnectionModal: PropTypes.func
};

const mapStateToProps = state => ({

});
// // no-op function to prevent dispatch prop being passed to component
// const mapDispatchToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    // onFileManagementAddExtension: extension=>{
    //     dispatch(fileManagementAddExtension(extension));
    // },
    // onFileManagementReload: flag=>{
    //     dispatch(fileManagementReload(flag));
    // },
    // onFileManagementResponse: response=>{
    //     dispatch(fileManagementResponse(response));
    // },
    // onFileManagementUpload: uploading=>{
    //     dispatch(fileManagementUpload(uploading));
    // },
    onOpenConnectionModal: id => {
        dispatch(setConnectionModalExtensionId(id));
        dispatch(openConnectionModal());
    },
    // onUpdateDeviceStatus: statusInfo=>{
    //     dispatch(updateDeviceStatus(statusInfo));
    // }
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
