import Box from '../box/box.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import { connect } from 'react-redux';
import { closeExtensionSelector } from '../../reducers/modals';
import bindAll from 'lodash.bindall'; // 绑定所有的通用方法
import Modal from '../../containers/modal.jsx';   // 窗口
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, List, message, Modal as AModal, Select, Table } from 'antd';
import styles from './extension-selector.css';
const { Option } = Select;

class ExtensionSelector extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'deepCopy',
            'handleChangeModuleId',
            'handleClose',
            'handleCloseModuleList',
            'handleCloseSlotList',
            'handleModalWaitCancel',
            'handleSelectExtension',
            'handleSelectSlot',
            'loadModule',
            'onWriteFileComplete',
            'openMessage',
            'toHexString',
            'uploadCode'
        ]);
        this.state = {
            messageWait: '',
            modules: [],
            selectedExtensionId: '',
            typeAlert: '',
            visibleModalModuleList: false,
            visibleModalSlotList: false,
            visibleModalWait: false
        };
    }
    deepCopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    handleChangeModuleId(newid, oldid) {
        // console.log(`Change moduleId from ${oldid} to ${newid}`);
        const _newid = Number(newid);

        //发送修改指令
        var queue = [0xFF, 0xFE, 0x06, 0x01, 0x10, oldid, _newid, (0xFF - oldid), (0xFF - _newid)
            , 0xFD, 0xFC];
        // console.log('发送修改ID指令: \r\n'+this.toHexString(queue));

        let conn = this.props.vm.runtime.peripheralExtensions[this.state.selectedExtensionId];
        conn.send(queue);
        this.setState({
            visibleModalModuleList: false
        });
        this.loadModule(this.state.selectedExtensionId);
    }
    handleClose() {
        this.props.onRequestClose();
    }
    handleCloseModuleList() {
        this.setState({ visibleModalModuleList: false });
        this.handleClose();
    }
    handleCloseSlotList() {
        this.setState({ visibleModalSlotList: false });
        this.handleClose();
    }
    handleModalWaitCancel() {
        this.setState({
            visibleModalWait: false
        });
    }
    handleSelectExtension(extensionId) {
        if (!this.props.vm.runtime.peripheralExtensions[extensionId]
            || !this.props.vm.runtime.peripheralExtensions[extensionId].isConnected()) {
            this.openMessage('warning', window.GetLocaleMsgs(document.documentElement.lang)['extensionSelectorMessageNotConnected']
            );
            return;
        }
        this.setState({
            selectedExtensionId: extensionId
        });
        switch (this.props.actionNum) {
            case 1:
                this.uploadCode(extensionId);
                break;
            case 2:
                this.loadModule(extensionId);
                break;
        }
    }
    handleSelectSlot(fileno){
        writeFile(this.state.selectedExtensionId, fileno);
        this.props.vm.addListener('WRITE_FILE_COMPLETE', this.onWriteFileComplete);
        this.setState({
            messageWait: (<FormattedMessage
                id="gui.extensionSelector.message.writingFile"
                defaultMessage="正在对设备写入文件……"
            />),
            visibleModalWait: true
        });
    }
    loadModule(extensionId) {
        if (!window.Extensions[extensionId].isModular === true) {
            this.openMessage('warning', window.GetLocaleMsgs(document.documentElement.lang)['extensionSelectorMessageNotModular']);
            return;
        }
        this.setState({
            messageWait: (<FormattedMessage
                id="gui.extensionSelector.message.loadingModules"
                defaultMessage="正在获取外接模块列表……"
            />),
            modules: [],
            visibleModalWait: true
        });
        const t = this;
        setTimeout(() => {
            t.props.vm.runtime.peripheralExtensions[extensionId].modules = [];
        }, 2000);
        setTimeout(() => {
            t.setState({
                modules: t.props.vm.runtime.peripheralExtensions[extensionId].modules,
                visibleModalModuleList: true,
                visibleModalWait: false
            });
        }, 5000);
    }
    onWriteFileComplete(){
        this.props.vm.removeListener('WRITE_FILE_COMPLETE', this.onWriteFileComplete);
        this.setState({
            visibleModalWait: false
        });
        this.handleClose();
        this.openMessage('success',window.GetLocaleMsgs(document.documentElement.lang)['extensionSelectorMessageWriteFileComplete']);
    }
    openMessage(type, content) {
        message[type](content);
    }
    toHexString(byteArray) {
        return Array.from(byteArray, function (byte) {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
        }).join(' ');
    }
    uploadCode(extensionId) {
        if (window.Extensions[extensionId].isSupportMultiFile !== true) {
            writeFile(extensionId, 1);
            this.props.vm.addListener('WRITE_FILE_COMPLETE', this.onWriteFileComplete);
            this.setState({
                messageWait: (<FormattedMessage
                    id="gui.extensionSelector.message.writingFile"
                    defaultMessage="正在对设备写入文件……"
                />),
                visibleModalWait: true
            });
        } else if (window.Extensions[extensionId].fileSystem !== true) {
            this.setState({
                visibleModalSlotList: true
            });
        } else {
            console.log('选择文件夹待写入');
        }
    }
    render() {
        if (!this.props.visible) return null;
        const slots=[{
            color: 'red',
            label: 'Code 1',
            value: '1'
        },{
            color: 'orange',
            label: 'Code 2',
            value: '2'
        },
        {
            color: 'yellow',
            label: 'Code 3',
            value: '3'
        },{
            color: 'green',
            label: 'Code 4',
            value: '4'
        },{
            color: 'cyan',
            label: 'Code 5',
            value: '5'
        },{
            color: 'blue',
            label: 'Code 6',
            value: '6'
        },{
            color: 'purple',
            label: 'Code 7',
            value: '7'
        },{
            color: '#ededed',
            label: 'Code 8',
            value: '8'
        },{
            color: '#ededed',
            label: 'Code 9',
            value: '9'
        },{
            color: '#ededed',
            label: 'Code 10',
            value: '10'
        }];
        const tableColumnsModuleList = [
            {
                title: (<FormattedMessage
                    id="gui.extensionSelector.modal.moduleList.column.name"
                    defaultMessage="名称"

                />),
                align: 'center',
                dataIndex: 'name',
                width: 100,
            },
            {
                title: (<FormattedMessage
                    id="gui.extensionSelector.modal.moduleList.column.id"
                    defaultMessage="编号"

                />),
                align: 'center',
                dataIndex: 'id',
                render: (text, record, index) => {
                    return <Select
                        defaultValue={text}
                        onChange={(option) => this.handleChangeModuleId(option, text)}
                        ref={this.state.selectRef}>
                        <Option value={record.type}>1</Option>
                        <Option value={record.type + 1}>2</Option>
                        <Option value={record.type + 2}>3</Option>
                        <Option value={record.type + 3}>4</Option>
                    </Select>
                },
                width: 100
            }
        ];
        return (
            <Modal
                className={styles.modalContent}
                contentLabel={<FormattedMessage
                    defaultMessage="选择设备"
                    id="gui.extensionSelector.title"
                />}
                onRequestClose={this.handleClose}
            >
                <Box>
                    <Box className={styles.body}>
                        <Box className={styles.activityArea}>
                            <List
                                bordered
                                dataSource={this.props.extensions}
                                renderItem={item => (
                                    <List.Item>
                                        <Button
                                            className={styles.itemButton}
                                            onClick={(e) => {
                                                if (e) {
                                                    e.preventDefault();
                                                }
                                                this.handleSelectExtension(item.id);
                                            }}>{item.name}</Button>
                                    </List.Item>
                                )}
                            />
                        </Box>
                    </Box>
                </Box>

                <AModal
                    title={<FormattedMessage
                        id="gui.extensionSelector.modal.wait.title"
                        defaultMessage="请稍后"
                    />}
                    visible={this.state.visibleModalWait}
                    onCancel={this.handleModalWaitCancel}
                    footer={null}
                >
                    <h4>{this.state.messageWait}</h4>
                </AModal>

                <AModal
                    title={<FormattedMessage
                        defaultMessage="外接模块列表"
                        id="gui.extensionSelector.modal.moduleList.title"
                    />}
                    visible={this.state.visibleModalModuleList}
                    onCancel={this.handleCloseModuleList}
                    footer={
                        <div
                            style={{ color: "red", textAlign: "center", width: "100%" }}>
                            <FormattedMessage
                                defaultMessage="*设置设备ID时只能连接一个设备，一台主控所连接的设备ID必须唯一。"
                                id="gui.extensionSelector.modal.moduleList.note"

                            />
                        </div>

                    }
                >
                    <Box>
                        <Box className={styles.body}>
                            <Box className={styles.activityArea}>
                                <Table
                                    columns={tableColumnsModuleList}
                                    dataSource={this.state.modules}
                                    pagination={false}
                                    rowKey={'id'}
                                    style={{
                                        height: '290px'
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </AModal>

                <AModal
                    title={<FormattedMessage
                        defaultMessage="选择存储的文件"
                        id="gui.extensionSelector.modal.slotList.title"
                    />}
                    visible={this.state.visibleModalSlotList}
                    onCancel={this.handleCloseSlotList}
                    footer={null}
                >
                    <Box>
                        <Box className={styles.body}>
                            <Box className={styles.activityArea}>
                                <List
                                    bordered
                                    dataSource={slots}
                                    renderItem={item => (
                                        <List.Item>
                                            <Button
                                                className={styles.itemButton}
                                                onClick={(e) => {
                                                    if (e) {
                                                        e.preventDefault();
                                                    }
                                                    this.handleSelectSlot(item.value);
                                                }}
                                                style={{background: item.color}}
                                                >{item.label}</Button>
                                        </List.Item>
                                    )}
                                />
                            </Box>
                        </Box>
                    </Box>
                </AModal>
            </Modal>
        );
    }
}

ExtensionSelector.propTypes = {
    actionNum: PropTypes.number,
    handleSelectExtension: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    selectedExtension: PropTypes.string,
    visible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    actionNum: state.scratchGui.extensionSelector.actionNum,
    extensions: state.scratchGui.extensionSelector.extensions
});

const mapDispatchToProps = dispatch => ({
    onRequestClose: () => {
        dispatch(closeExtensionSelector());
    }
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExtensionSelector));
