exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #3692f8 */ /* #e9f2ff */ /* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .extension-status_extension-container_3P81g{\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    position: relative;\r\n    /* 2024-06-24 改成小手 */\r\n    cursor: pointer;\r\n} .extension-status_extension-icon_264N8 {\r\n    height: 3rem;\r\n    padding: 0.3rem 0.6rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    user-drag: none;\r\n    border-radius: 0.25rem;\r\n} .extension-status_extension-icon_264N8:hover {\r\n    background-color: hsla(215, 100%, 65%, 0.15);\r\n} .extension-status_extension-status_3eYKQ{\r\n    position: absolute;\r\n    top: 0rem;\r\n    right: 0rem;\r\n    padding: 0.375rem;\r\n    pointer-events: none; \r\n}\r\n", ""]);

// exports
exports.locals = {
	"extension-container": "extension-status_extension-container_3P81g",
	"extensionContainer": "extension-status_extension-container_3P81g",
	"extension-icon": "extension-status_extension-icon_264N8",
	"extensionIcon": "extension-status_extension-icon_264N8",
	"extension-status": "extension-status_extension-status_3eYKQ",
	"extensionStatus": "extension-status_extension-status_3eYKQ"
};