import styles from '../containers/blocks.css';
import icon from './info.svg';
/**
 * 提示条
 */
export default function (Blockly){

  const oldShow_ = Blockly.Tooltip.show_;

  Blockly.Tooltip.show_ = function() {
    if (!Blockly.Tooltip.DIV) {
      return;
    }
    oldShow_.apply(this);
    var img = document.createElement('img');
    img.src = icon;
    img.className = styles["toolTipInfo"];
    Blockly.Tooltip.DIV.appendChild(img)
  }
}