const EXTENSION_SELECTOR_ADD_EXTENSION = 'scratch-gui/extension-selector/EXTENSION_SELECTOR_ADD_EXTENSION';
const EXTENSION_SELECTOR_AFTER_SELECTING = 'scratch-gui/extension-selector/EXTENSION_SELECTOR_AFTER_SELECTING';

const initialState = {
    actionNum: 0,
    extensions: []
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    if (!action)
        return state;
    switch (action.type) {
        case EXTENSION_SELECTOR_ADD_EXTENSION:
            return Object.assign({}, state, {
                extensions: state.extensions.concat(action.extension)
            });
        case EXTENSION_SELECTOR_AFTER_SELECTING:
            return Object.assign({}, state, {
                actionNum: action.actionNum
            });
        default:
            return state;
    }
};

const extensionSelectorAddExtension = function (extension) {
    return {
        type: EXTENSION_SELECTOR_ADD_EXTENSION,
        extension
    }
}

const extensionSelectorAfterSelecting = function (actionNum) {
    return {
        type: EXTENSION_SELECTOR_AFTER_SELECTING,
        actionNum
    }
}

export {
    reducer as default,
    initialState as extensionSelectorInitialState,
    extensionSelectorAddExtension,
    extensionSelectorAfterSelecting
};