import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import ScanningStep from '../../containers/scanning-step.jsx';
import AutoScanningStep from '../../containers/auto-scanning-step.jsx';
import ConnectingStep from './connecting-step.jsx';
import ConnectedStep from './connected-step.jsx';
import ErrorStep from './error-step.jsx';
import UnavailableStep from './unavailable-step.jsx';

import AutoScanningUsbStep from '../../containers/auto-scanning-usb-step.jsx';
import AutoScanningWebusbStep from '../../containers/auto-scanning-webusb-step.jsx';

import ConnectingUsbStep from './connecting-usb-step.jsx';
import ConnectingWebusbStep from './connecting-webusb-step.jsx';

import ConnectedUsbStep from './connected-usb-step.jsx';
import ConnectedWebusbStep from './connected-webusb-step.jsx';

import UnavailableUsbStep from './unavailable-usb-step.jsx';
import UnavailableWebusbStep from './unavailable-webusb-step.jsx';

import AutoScanningConnectingStep from '../../containers/auto-scanning-connecting-step.jsx';

import styles from './connection-modal.css';

import { Button, Col, Icon, Row, Switch } from 'antd';

import BleIcon from './icons/bleIcon.svg.js';
import UsbIcon from './icons/usbIcon.svg.js';

import { FormattedMessage } from 'react-intl';

const bleIcon = () => (
    <BleIcon />
);
const usbIcon = () => (
    <UsbIcon />
);

const PHASES = keyMirror({
    scanning: null,
    connecting: null,
    connected: null,
    error: null,
    unavailable: null
});

function isSupportWebBluetooth(extensionId, props) {
    // console.log(JSON.stringify(props));

    //记录UseLink的值
    // if (jQuery('#checkbox_useLink').length > 0
    //     && jQuery('#checkbox_useLink').prop('checked') === true
    if (window.CookieManager.getCookie('useLink') === 'true'
        || window.preferences && window.preferences.value.useLink === true
    ) {
        window.Extensions[extensionId].useLink = true;
    } else {
        window.Extensions[extensionId].useLink = false;
    }

    //如果勾选了就强制禁用Web Bluetooth
    if (window.Extensions[extensionId].useLink === true) {
        return false;
    }

    if (!navigator.bluetooth) {
        return false;
    }
    return true;
}

const ConnectionModalComponent = props => (
    <Modal
        className={styles.modalContent}
        contentLabel={props.name}
        headerClassName={styles.header}
        headerImage={props.connectionSmallIconURL}
        id="connectionModal"
        onHelp={props.onHelp}
        onRequestClose={props.onCancel}
        onStartLink={props.onStartLink}
    >
        {/* <Box className={styles.body}> */}
        {
            window.Extensions[props.extensionId].commBoth ?
                <Box>
                    {props.phase === PHASES.scanning && props.isCommBle && <ScanningStep {...props} />}
                    {props.phase === PHASES.scanning && !props.isCommBle && <AutoScanningUsbStep {...props} />}

                    {props.phase === PHASES.connecting && props.isCommBle && <ConnectingStep {...props} />}
                    {props.phase === PHASES.connecting && !props.isCommBle && <ConnectingUsbStep {...props} />}

                    {props.phase === PHASES.connected && props.isCommBle && <ConnectedStep {...props} />}
                    {props.phase === PHASES.connected && !props.isCommBle && <ConnectedUsbStep {...props} />}

                    {props.phase === PHASES.error && <ErrorStep {...props} />}

                    {props.phase === PHASES.unavailable && props.isCommBle && <UnavailableStep {...props} />}
                    {props.phase === PHASES.unavailable && !props.isCommBle && <UnavailableUsbStep {...props} />}

                    <div className={styles.commModeArea}>
                        <Switch checkedChildren={<Icon component={bleIcon} />} unCheckedChildren={<Icon component={usbIcon} />} checked={props.isCommBle} onChange={props.onToggle} />
                    </div>
                </Box> :
                <Box flagScan={props.flagScan}>
                    {props.phase === PHASES.scanning && props.useAutoScan === false
                        && (window.Extensions[props.extensionId].type === 'ble' && isSupportWebBluetooth(props.extensionId) && <AutoScanningStep {...props} />
                            || window.Extensions[props.extensionId].type === 'ble' && !isSupportWebBluetooth(props.extensionId)
                            && props.vm.runtime.peripheralExtensions[props.extensionId].parameters
                            && props.vm.runtime.peripheralExtensions[props.extensionId].parameters.autoReconnectEnabled === true
                            && props.vm.runtime.peripheralExtensions[props.extensionId].parameters.autoReconnectPeripheralId.toString().length > 0
                            && <AutoScanningConnectingStep {...props} />
                            || (window.Extensions[props.extensionId].type !== 'ble' || !isSupportWebBluetooth(props.extensionId, props))
                            && (!props.vm.runtime.peripheralExtensions[props.extensionId].parameters
                                || props.vm.runtime.peripheralExtensions[props.extensionId].parameters.autoReconnectEnabled === false
                                || !props.vm.runtime.peripheralExtensions[props.extensionId].parameters.autoReconnectPeripheralId)
                            && <ScanningStep {...props} />
                        )}

                    {props.phase === PHASES.scanning && props.useAutoScan === true &&
                        (props.extensionId.toLowerCase().includes('webusb') && <AutoScanningWebusbStep {...props} />
                            || props.extensionId.toLowerCase().includes('usb') && !props.extensionId.toLowerCase().includes('webusb') && <AutoScanningUsbStep {...props} />
                            || !props.extensionId.toLowerCase().includes('usb') && !props.extensionId.toLowerCase().includes('webbt') && <AutoScanningStep {...props} />
                        )}

                    {props.phase === PHASES.connecting && props.extensionId.toLowerCase().includes('webusb') && <ConnectingWebusbStep {...props} />}
                    {props.phase === PHASES.connecting && props.extensionId.toLowerCase().includes('usb') && !props.extensionId.toLowerCase().includes('webusb') && <ConnectingUsbStep {...props} />}
                    {props.phase === PHASES.connecting && !props.extensionId.toLowerCase().includes('usb') && !props.extensionId.toLowerCase().includes('webbt') && <ConnectingStep {...props} />}

                    {props.phase === PHASES.connected && props.extensionId.toLowerCase().includes('webusb') && <ConnectedWebusbStep {...props} />}
                    {props.phase === PHASES.connected && props.extensionId.toLowerCase().includes('usb') && !props.extensionId.toLowerCase().includes('webusb') && <ConnectedUsbStep {...props} />}
                    {props.phase === PHASES.connected && !props.extensionId.toLowerCase().includes('usb') && !props.extensionId.toLowerCase().includes('webbt') && <ConnectedStep {...props} />}

                    {props.phase === PHASES.error && <ErrorStep {...props} />}

                    {props.phase === PHASES.unavailable && props.extensionId.toLowerCase().includes('webusb') && <UnavailableWebusbStep {...props} />}
                    {props.phase === PHASES.unavailable && props.extensionId.toLowerCase().includes('usb') && !props.extensionId.toLowerCase().includes('webusb') && <UnavailableUsbStep {...props} />}
                    {props.phase === PHASES.unavailable && !props.extensionId.toLowerCase().includes('usb') && !props.extensionId.toLowerCase().includes('webbt') && <UnavailableStep {...props} />}

                    {
                        (props.vm.runtime.peripheralExtensions[props.extensionId].parameters) &&
                        <Row style={{ marginBottom: '5px' }}>
                            <Col span={20} style={{ textAlign: 'right' }} >

                                <Switch defaultChecked={props.vm.runtime.peripheralExtensions[props.extensionId].parameters.autoReconnectEnabled} onChange={props.onChangeAutoReconnectEnabled} />
                            </Col>
                            <Col span={4} style={{ marginTop: '3px' }}>
                                <div><FormattedMessage
                                    defaultMessage="自动重连"
                                    id="gui.connectionModal.autoReconnect"
                                /></div>
                            </Col>
                        </Row>}
                </Box>
        }
    </Modal>
);

ConnectionModalComponent.propTypes = {
    connectingMessage: PropTypes.node.isRequired,
    connectionSmallIconURL: PropTypes.string,
    connectionTipIconURL: PropTypes.string,
    name: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onChangeAutoReconnectEnabled: PropTypes.func.isRequired,
    onHelp: PropTypes.func.isRequired,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired,
    title: PropTypes.string.isRequired,
    useAutoScan: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onStartLink: PropTypes.func.isRequired
};

ConnectionModalComponent.defaultProps = {
    connectingMessage: 'Connecting'
};

export {
    ConnectionModalComponent as default,
    PHASES
};
