import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import superbotIconURL from './superbot/superbot.png';
import superbotInsetIconURL from './superbot/superbot-small.png';
import superbotConnectionIconURL from './superbot/superbot-illustration.svg';
import superbotConnectionSmallIconURL from './superbot/superbot-small.png';
import superbotConnectionTipIconURL from './superbot/superbot-button-illustration.gif';

import robotmasterIconURL from './robotmaster/robotmaster.png';
import robotmasterInsetIconURL from './robotmaster/robotmaster-small.png';
import robotmasterConnectionIconURL from './robotmaster/robotmaster-illustration.svg';
import robotmasterConnectionSmallIconURL from './robotmaster/robotmaster-small.png';
import robotmasterConnectionTipIconURL from './robotmaster/robotmaster-button-illustration.gif';

import robotmasterUsbIconURL from './robotmasterUsb/robotmasterUsb.png';
import robotmasterUsbInsetIconURL from './robotmasterUsb/robotmasterUsb-small.png';
import robotmasterUsbConnectionIconURL from './robotmasterUsb/robotmasterUsb-illustration.svg';
import robotmasterUsbConnectionSmallIconURL from './robotmasterUsb/robotmasterUsb-small.png';
import robotmasterUsbConnectionTipIconURL from './robotmasterUsb/robotmasterUsb-button-illustration.gif';

import robotmasterTournamentBleIconURL from './robotmasterTournamentBle/default.png';
import robotmasterTournamentBleInsetIconURL from './robotmasterTournamentBle/small.png';
import robotmasterTournamentBleConnectionIconURL from './robotmasterTournamentBle/illustration.svg';
import robotmasterTournamentBleConnectionSmallIconURL from './robotmasterTournamentBle/small.png';
import robotmasterTournamentBleConnectionTipIconURL from './robotmasterTournamentBle/button-illustration.gif';

import gibbonIconURL from './gibbon/gibbon.jpg';
import gibbonInsetIconURL from './gibbon/gibbon-small.png';
import gibbonConnectionIconURL from './gibbon/gibbon-illustration.svg';
import gibbonConnectionSmallIconURL from './gibbon/gibbon-small.png';
import gibbonConnectionTipIconURL from './gibbon/gibbon-button-illustration.gif';

import superbotMiniBleIconURL from './superbotMiniBle/default.jpg';
import superbotMiniBleInsetIconURL from './superbotMiniBle/small.png';
import superbotMiniBleConnectionIconURL from './superbotMiniBle/illustration.svg';
import superbotMiniBleConnectionSmallIconURL from './superbotMiniBle/small.png';
import superbotMiniBleConnectionTipIconURL from './superbotMiniBle/button-illustration.gif';

import icb05BleIconURL from './icb05Ble/default.png';
import icb05BleInsetIconURL from './icb05Ble/small.png';
import icb05BleConnectionIconURL from './icb05Ble/illustration.svg';
import icb05BleConnectionSmallIconURL from './icb05Ble/small.png';
import icb05BleConnectionTipIconURL from './icb05Ble/button-illustration.gif';

import creativeBoardIconURL from './creativeBoard/default.jpg';
import creativeBoardInsetIconURL from './creativeBoard/small.png';
import creativeBoardConnectionIconURL from './creativeBoard/illustration.svg';
import creativeBoardConnectionSmallIconURL from './creativeBoard/small.png';
import creativeBoardConnectionTipIconURL from './creativeBoard/tip.png';

export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="Coding Cube"
                description="Name for the 'icb05Ble' extension"
                id="gui.extension.icb05Ble.name"
            />
        ),
        extensionId: 'icb05Ble',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'icb05Ble' extension"
                id="gui.extension.icb05Ble.collaborator"
            />
        ),
        iconURL: icb05BleIconURL,
        insetIconURL: icb05BleInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything you want."
                description="Description for the 'icb05Ble' extension"
                id="gui.extension.icb05Ble.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        usbRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: icb05BleConnectionIconURL,
        connectionSmallIconURL: icb05BleConnectionSmallIconURL,
        connectionTipIconURL: icb05BleConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Superbot."
                id="gui.extension.icb05Ble.connectingMessage"
            />
        ),
        helpLink: 'http://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Robotmaster Tournament"
                description="Name for the 'RobotmasterTournamentBle' extension"
                id="gui.extension.robotmasterTournamentBle.name"
            />
        ),
        extensionId: 'robotmasterTournamentBle',
        collaborator: (
            <FormattedMessage
                defaultMessage="Tudao Technology"
                description="Collaborator for the 'RobotmasterTournamentBle' extension"
                id="gui.extension.robotmasterTournamentBle.collaborator"
            />
        ),
        iconURL: robotmasterTournamentBleIconURL,
        insetIconURL: robotmasterTournamentBleInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="A robot that can be controlled by a Bluetooth LE connection."
                description="Description for the 'RobotmasterTournamentBle' extension"
                id="gui.extension.robotmasterTournamentBle.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        usbRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: robotmasterTournamentBleConnectionIconURL,
        connectionSmallIconURL: robotmasterTournamentBleConnectionSmallIconURL,
        connectionTipIconURL: robotmasterTournamentBleConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their RobotmasterTournamentBle."
                id="gui.extension.robotmasterTournamentBle.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="SuperBot Mini"
                description="Name for the 'SuperbotMiniBle' extension"
                id="gui.extension.superbotMiniBle.name"
            />
        ),
        extensionId: 'superbotMiniBle',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'SuperbotMiniBle' extension"
                id="gui.extension.superbotMiniBle.collaborator"
            />
        ),
        iconURL: superbotMiniBleIconURL,
        insetIconURL: superbotMiniBleInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything you want."
                description="Description for the 'SuperbotMiniBle' extension"
                id="gui.extension.superbotMiniBle.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        usbRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: superbotMiniBleConnectionIconURL,
        connectionSmallIconURL: superbotMiniBleConnectionSmallIconURL,
        connectionTipIconURL: superbotMiniBleConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Superbot."
                id="gui.extension.superbotMiniBle.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="RobotMaster"
                description="Name for the 'Robotmaster' extension"
                id="gui.extension.robotmaster.name"
            />
        ),
        extensionId: 'robotmaster',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'Robotmaster' extension"
                id="gui.extension.robotmaster.collaborator"
            />
        ),
        iconURL: robotmasterIconURL,
        insetIconURL: robotmasterInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything you want."
                description="Description for the 'Robotmaster' extension"
                id="gui.extension.robotmaster.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: robotmasterConnectionIconURL,
        connectionSmallIconURL: robotmasterConnectionSmallIconURL,
        connectionTipIconURL: robotmasterConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Robotmaster."
                id="gui.extension.robotmaster.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Robotmaster(USB Mode)"
                description="Name for the 'RobotmasterUsb' extension"
                id="gui.extension.robotmasterUsb.name"
            />
        ),
        extensionId: 'robotmasterUsb',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'RobotmasterUsb' extension"
                id="gui.extension.robotmasterUsb.collaborator"
            />
        ),
        iconURL: robotmasterUsbIconURL,
        insetIconURL: robotmasterUsbInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="A robot that can be controlled by connecting a USB cable."
                description="Description for the 'RobotmasterUsb' extension"
                id="gui.extension.robotmasterUsb.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: false,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: robotmasterUsbConnectionIconURL,
        connectionSmallIconURL: robotmasterUsbConnectionSmallIconURL,
        connectionTipIconURL: robotmasterUsbConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their RobotmasterUsb."
                id="gui.extension.robotmasterUsb.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="SuperBot"
                description="Name for the 'Superbot' extension"
                id="gui.extension.superbot.name"
            />
        ),
        extensionId: 'superbot',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'Superbot' extension"
                id="gui.extension.superbot.collaborator"
            />
        ),
        iconURL: superbotIconURL,
        insetIconURL: superbotInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything you want."
                description="Description for the 'Superbot' extension"
                id="gui.extension.superbot.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: superbotConnectionIconURL,
        connectionSmallIconURL: superbotConnectionSmallIconURL,
        connectionTipIconURL: superbotConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their Superbot."
                id="gui.extension.superbot.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    
    {
        name: (
            <FormattedMessage
                defaultMessage="Makerzoid Joystick"
                description="Name for the 'gibbon' extension"
                id="gui.extension.gibbon.name"
            />
        ),
        extensionId: 'gibbon',
        collaborator: (
            <FormattedMessage
                defaultMessage="Makerzoid"
                description="Collaborator for the 'gibbon' extension"
                id="gui.extension.gibbon.collaborator"
            />
        ),
        iconURL: gibbonIconURL,
        insetIconURL: gibbonInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Programmable remote control."
                description="Description for the 'gibbon' extension"
                id="gui.extension.gibbon.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: false,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gibbonConnectionIconURL,
        connectionSmallIconURL: gibbonConnectionSmallIconURL,
        connectionTipIconURL: gibbonConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their gibbon."
                id="gui.extension.gibbon.connectingMessage"
            />
        ),
        helpLink: 'https://www.makerzoid.com/'
    },
    // {
    //     name: (
    //         <FormattedMessage
    //             defaultMessage="Magic Stick"
    //             id="gui.extension.creativeBoard.name"
    //         />
    //     ),
    //     extensionId: 'creativeBoard',
    //     collaborator: (
    //         <FormattedMessage
    //             defaultMessage="Makerzoid"
    //             description="Collaborator for the 'creativeBoard' extension"
    //             id="gui.extension.creativeBoard.collaborator"
    //         />
    //     ),
    //     iconURL: creativeBoardIconURL,
    //     insetIconURL: creativeBoardInsetIconURL,
    //     description: (
    //         <FormattedMessage
    //             defaultMessage="Make anything you want."
    //             id="gui.extension.creativeBoard.description"
    //         />
    //     ),
    //     featured: true,
    //     disabled: false,
    //     bluetoothRequired: true,
    //     usbRequired: true,
    //     internetConnectionRequired: false,
    //     launchPeripheralConnectionFlow: true,
    //     useAutoScan: false,
    //     connectionIconURL: creativeBoardConnectionIconURL,
    //     connectionSmallIconURL: creativeBoardConnectionSmallIconURL,
    //     connectionTipIconURL: creativeBoardConnectionTipIconURL,
    //     connectingMessage: (
    //         <FormattedMessage
    //             defaultMessage="Connecting"
    //             description="Message to help people connect to their devices."
    //             id="gui.extension.creativeBoard.connectingMessage"
    //         />
    //     ),
    //     helpLink: 'http://www.makerzoid.com/'
    // }
];
