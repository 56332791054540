exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #3692f8 */ /* #e9f2ff */ /* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .preferences-modal_modal-content_2i7UO {\r\n    height: 200px;\r\n    width: 300px;\r\n} .ant-layout-footer {\r\n    padding: 0 10px;\r\n    height: 50px;\r\n    line-height: 50px;\r\n} .ant-table-thead>tr>th {\r\n    padding: 10px;\r\n} .ant-table-tbody>tr>td {\r\n    padding: 6px;\r\n}\r\n\r\n", ""]);

// exports
exports.locals = {
	"modal-content": "preferences-modal_modal-content_2i7UO",
	"modalContent": "preferences-modal_modal-content_2i7UO"
};