import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './extension-status.css';

const ExtensionStatusComponent = function (props) {
    const {
        blockIconURI,
        onClick,
        status,
        ...componentProps
    } = props;
    return (
        <div className={styles.extensionContainer}>
            <img
                className={styles.extensionIcon}
                draggable={false}
                onClick={onClick}
                src={blockIconURI}
                {...componentProps}
            />
            <img
                className={styles.extensionStatus}
                draggable={false}
                src={status}
            />
        </div>
    );
};
ExtensionStatusComponent.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired
};
ExtensionStatusComponent.defaultProps = {
};
export default ExtensionStatusComponent;
