import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import { connect } from 'react-redux';
import { closeFileManager } from '../../reducers/modals';
import bindAll from 'lodash.bindall'; // 绑定所有的通用方法
import Modal from '../../containers/modal.jsx';   // 窗口
import { FormattedMessage, injectIntl } from 'react-intl';
import {
    Alert, Button, Col, ConfigProvider, Icon, Input, Layout, message, Modal as AModal,
    Progress, Row, Select, Table, Tooltip, Upload
} from 'antd';
import styles from './file-manager.css';
import {
    fileManagementClearItems,
    fileManagementUpdateUploadStatus
} from '../../reducers/file-manager';
import enUS from 'antd/es/locale/en_US.js';
import zhCN from 'antd/es/locale/zh_CN.js';
import jaJP from 'antd/es/locale/ja_JP.js';
import ukUA from 'antd/es/locale/uk_UA.js';

class FileManager extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'addFolder',
            'cancelUploadFile',
            'getDetail',
            'getLocale',
            'getSummury',
            'handleBeforeUpload',
            'handleChangeExtension',
            'handleChangePage',
            'handleClose',
            'handleDelete',
            'handleFolderAdd',
            'handleFolderUp',
            'handleNewFolderNameChange',
            'handleReload',
            'handleTableChange',
            'openMessage',
            'strToUtf8'
        ]);
        this.state = {
            currentPage: 1,
            currentPath: '/',
            fileResourceItems: [],
            locale: enUS,
            /**
             * true表明正在加载文件的状态
             */
            loadingFile: false,
            newFolderName: '',
            pagination: {
                current: 1,
                pageSize: 5,
                total: 0,
            },
            selectedExtension: '',
            visibleModal: false
        };
    }
    addFolder(){
        if (this.state.loadingFile === true || this.props.visibleAlert === true) {
            return;
        }
        this.setState({
            newFolderName: '',
            visibleModal: true
        });
    }
    cancelUploadFile() {
        let ext = this.props.vm.runtime.peripheralExtensions[this.state.selectedExtension];
        if(ext){
            ext.manageFileCancelUpload();
            ext.upload.interruptUpload = true;
        }
        let i = 1;
        //连关3次，防止延后发送的ACK帧影响界面显示
        const flag = setInterval(() => {
            if (i > 3) {
                clearInterval(flag);
                return;
            }
            this.props.onFileManagementUpdateUploadStatus(false);
            i++;
        }, 500);
    }
    componentDidUpdate(prevProps) {
        if (this.props.version === prevProps.version) {
            return;
        }
        if (this.props.reloadFlag !== prevProps.reloadFlag) {
            this.getSummury(this.state.selectedExtension, this.state.currentPath);
        }
    }
    /**
     * 加载某一页的文件条目
     * @param {number} page 
     */
    getDetail(page) {
        const ext = this.props.vm.runtime.peripheralExtensions[this.state.selectedExtension];
        if (!ext || !ext.manageFileGetListSummury) {
            console.error('Extension error: can not find manageFileGetListSummury() method!');
            return;
        }
        if (!ext.manageFileGetFileInfo) {
            console.error('Extension error: can not find manageFileGetFileInfo() method!');
            return;
        }
        this.setState({
            currentPage: page,
            loadingFile: true
        });
        const start = (page - 1) * this.props.pager.pageSize + 1;
        let end = page * this.props.pager.pageSize;
        end = end > this.props.itemCount ? this.props.itemCount : end;

        // console.log(`start=${start}, end=${end}`);
        // let count = 0;
        // let item = start;
        // let lastVersion = this.props.version;
        // const maxTry = 10;
        const t = this;

        for(let i=start;i<=end;i++){
            setTimeout(()=>{
                ext.manageFileGetListItem(i, t.state.currentPath);
            }, (4-end+i)*500);
        }
        //判定“获取目录条目”操作执行完了之后再执行“获取文件信息”
        let flag=setInterval(() => {
            if(t.props.items.length===(end-start+1)){
                clearInterval(flag);
                this.setState({
                    loadingFile: false
                });

                // console.log(`pageSize=${t.props.items.length},items=${JSON.stringify(t.props.items)}`);
                t.getSize(ext);
            }
        }, 100);

        // let flagReq = setInterval(() => {
        //     if (item > end) {
        //         clearInterval(flagReq);
        //         t.getSize(ext);
        //         return;
        //     }
        //     else if (t.props.version === lastVersion && count >= maxTry) {
        //         const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        //         const err=`${msgs['messageFileManagerGetDetailItemError-1']}${item}${msgs['messageFileManagerGetDetailItemError-2']}`;
        //         console.error=err;
        //         t.openMessage('error', err);
        //         clearInterval(flagReq);
        //         return;
        //     } else if (t.props.version === lastVersion) {
        //         ext.manageFileGetListItem(item, t.state.currentPath);
        //         count++;
        //         return;
        //     } else {
        //         item++;
        //         count = 0;
        //         lastVersion = t.props.version;
        //     }
        // }, 500);
    }
    getLocale(){
        switch(document.documentElement.lang.substring(0,2)){
            case 'zh':
                return zhCN;
            case 'ja':
                return jaJP;
            case 'uk':
                return ukUA;
            default:
                return enUS;
        }
    }
    getSize(ext) {
        if (!this.props.items || this.props.items.length === 0) {
            return;
        }
        // let count = 0;
        // let idx = 0;
        // let lastVersion = this.props.version;
        // const maxTry = 10;
        const pageSize = this.props.items.length;
        const t = this;

        for(let i=0;i<pageSize;i++){
            setTimeout(()=>{
                if(t.props.items[i]&&t.props.items[i].type===0x01){
                    ext.manageFileGetFileInfo(t.props.items[i].path);
                }
            }, i*500);
        }

        // let flagReq = setInterval(() => {
        //     if (idx >= pageSize) {
        //         clearInterval(flagReq);
        //         t.setState({
        //             loadingFile: false
        //         });
        //         return;
        //     }
        //     else if (t.props.version === lastVersion && count >= maxTry) {
        //         console.error(`Request file info error`);
        //         clearInterval(flagReq);
        //         t.setState({
        //             loadingFile: false
        //         });
        //         return;
        //     } else if (t.props.version === lastVersion && t.props.items[idx] && t.props.items[idx].type === 0x01) {
        //         ext.manageFileGetFileInfo(t.props.items[idx].path);
        //         count++;
        //         return;
        //     } else {
        //         count = 0;
        //         idx++;
        //         lastVersion = t.props.version;
        //     }
        // }, 500);
    }
    /**
     * 加载文件总数并获取第一页的文件条目
     * @param {*} extensionId 扩展编号
     * @param {*} path 路径
     * @returns 
     */
    getSummury(extensionId, path = '/') {
        let ext = this.props.vm.runtime.peripheralExtensions[extensionId];
        if (!ext || !ext.manageFileGetListSummury) {
            console.error('Extension error: can not find manageFileGetList() method!');
            return;
        }
        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        if (!ext.isConnected()) {
            this.openMessage('warning', msgs['messageChooseExtensionNotConnected']);
            return;
        }
        //忽略状态检测
        ext.ignoreStatusChecker(true);
        this.setState({
            loadingFile: true,
            currentPath: path,
            selectedExtension: extensionId
        });
        let lastVersion = this.props.version;
        let count = 0;
        const maxTry = 10;
        const t = this;
        let flagReq = setInterval(() => {
            if (t.props.version === lastVersion && count < maxTry) {
                ext.manageFileGetListSummury(path);
                count++;
                return;
            }
            clearInterval(flagReq);
            if (t.props.version === lastVersion) {
                t.openMessage('error', msgs['messageFileManagerGetSummuryLoadFail']);
                t.setState({
                    loadingFile: false
                })
                return;
            }
            t.setState({
                selectedExtension: extensionId,
                currentPath: path
            });
            t.getDetail(1);
            clearInterval(flagReq);
        }, 500);
    }
    handleBeforeUpload(file) {
        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        if (this.state.currentPath === '/') {
            this.openMessage('warning', msgs['messageFileManagerUploadFileRootDirectory']);
            return false;
        }
        if (!['image/png', 'audio/wav'].includes(file.type) && !file.name.endsWith('.L')) {
            this.openMessage('warning', msgs["messageFileManagerUploadFileNotSupportFormat"]);
            return false;
        }
        if (file.size >= (128 * 256 * 256)) {
            this.openMessage('warning', msgs["messageFileManagerUploadFileTooLarge"]);
            return false;
        }

        let ext = this.props.vm.runtime.peripheralExtensions[this.state.selectedExtension];
        if (!ext || !ext.isConnected()) {
            this.openMessage('warning', msgs['messageChooseExtensionNotConnected']);
            return false;
        }
        this.props.onFileManagementUpdateUploadStatus(true);
        // 以二进制形式读取文件
        const reader = new FileReader();
        const _packetSize = 128;
        let path = this.state.currentPath + '/' + file.name;
        reader.onload = function (event) {
            const uint8Array = new Uint8Array(event.target.result);
            ext.uploadFileExecute(uint8Array, _packetSize, path);
        };
        reader.readAsArrayBuffer(file);

        return false;
    }
    handleChangeExtension(value, option) {
        this.setState({
            selectedExtension: value
        });
        this.props.onFileManagementClearItems();
        //关闭上传进度条
        this.props.onFileManagementUpdateUploadStatus(false);
    }
    handleChangePage(page) {
        if(this.state.loadingFile===true){
            return;
        }
        this.setState({
            currentPage: page
        });
        this.props.onFileManagementClearItems();
        this.getDetail(page);
    }
    handleClose() {
        let ext = this.props.vm.runtime.peripheralExtensions[this.state.selectedExtension];
        if (ext) {
            ext.ignoreStatusChecker(false);
        }
        this.cancelUploadFile();
        this.props.onRequestClose();
    }
    handleDelete(path) {
        const codes = this.strToUtf8(path);
        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        if (codes.length > 110) {
            this.openMessage('warning', msgs['messageFileManagerPathTooLong']);
            return;
        }
        let ext = this.props.vm.runtime.peripheralExtensions[this.state.selectedExtension];
        if (!ext || !ext.manageFileDelete) {
            console.error('Extension error: can not find manageFileDelete() method!');
            return;
        }
        if (!ext.isConnected()) {
            this.openMessage('warning', msgs['messageChooseExtensionNotConnected']);
            return;
        }
        let lastVersion = this.props.version;
        let count = 0;
        const maxTry = 10;
        const t = this;
        let flagReq = setInterval(() => {
            if (t.props.version === lastVersion && count < maxTry) {
                ext.manageFileDelete(path);
                count++;
                return;
            }
            clearInterval(flagReq);
            if (t.props.version === lastVersion) {
                t.openMessage('error', msgs['messageFileManagerDeleteError']);
                return;
            }
            t.getSummury(t.state.selectedExtension, t.state.currentPath);
        }, 500);
    }
    handleFolderAdd() {
        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        if (this.state.currentPath === '/') {
            this.openMessage('warning', msgs['messageFileManagerAddFolderRootDirectory']);
            return;
        }
        const path = `${this.state.currentPath}/${this.state.newFolderName}`;
        const codes = this.strToUtf8(path);
        if (codes.length > 110) {
            this.openMessage('warning', msgs['messageFileManagerPathTooLong']);
            return;
        }
        if (path.split('/').length > 5) {
            this.openMessage('warning', msgs['messageFileManagerAddFolderLevelToolong']);
            return;
        }
        let ext = this.props.vm.runtime.peripheralExtensions[this.state.selectedExtension];
        if (!ext || !ext.manageFileMakeDirectory) {
            console.error('Extension error: can not find manageFileMakeDirectory() method!');
            return;
        }
        if (!ext.isConnected()) {
            this.openMessage('warning', msgs['messageChooseExtensionNotConnected']);
            return;
        }
        this.setState({
            visibleModal: false
        });
        let lastVersion = this.props.version;
        let count = 0;
        const maxTry = 10;
        const t = this;
        let flagReq = setInterval(() => {
            if (t.props.version === lastVersion && count < maxTry) {
                ext.manageFileMakeDirectory(path);
                count++;
                return;
            }
            clearInterval(flagReq);
            if (t.props.version === lastVersion) {
                t.openMessage('error', msgs['messageFileManagerAddFolderError']);
                return;
            }
            t.getSummury(t.state.selectedExtension, t.state.currentPath);
        }, 500);
    }
    handleFolderUp() {
        if (!this.state.selectedExtension || !this.state.currentPath || this.state.currentPath === '/') {
            return;
        }
        let patharr = this.state.currentPath.split('/');
        patharr.pop();
        this.getSummury(this.state.selectedExtension, patharr.length === 1 ? '/' : patharr.join('/'));
    }
    handleNewFolderNameChange(e) {
        this.setState({
            newFolderName: e.target.value
        })
    }
    handleReload() {
        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        if (this.state.loadingFile === true) {
            return;
        }
        if (!this.state.selectedExtension) {
            this.openMessage('warning', msgs['messageFileManagerNotSelectedExtension']);
            return;
        }
        this.getSummury(this.state.selectedExtension, this.state.currentPath);
    }
    handleTableChange(pagination, filters, sorter) {
        const newitem = {
            current: pagination.current
        };
        const t = this;
        this.setState(() => (Object.assign(t.state.pagination, newitem)));
    }
    openMessage(type, content) {
        let msg = message[type];
        msg(content);
    }
    /**
     * 字符串转utf8字节数组
     * @param {*} str 
     * @returns 
     */
    strToUtf8(str) {
        var utf8 = [];
        for (var i = 0; i < str.length; i++) {
            var charcode = str.charCodeAt(i);
            if (charcode < 0x80) utf8.push(charcode);
            else if (charcode < 0x800) {
                utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
            }
            else if (charcode < 0xd800 || charcode >= 0xe000) {
                utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
            }
            else {
                i++;
                charcode = ((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff);
                utf8.push(0xf0 | (charcode >> 18), 0x80 | ((charcode >> 12) & 0x3f), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
            }
        }
        return utf8;
    }
    render() {
        if (!this.props.visible) return null;
        const fileResourceColumns = [
            {
                align: 'center',
                dataIndex: 'type',
                render: (text, record, index) => (
                    <Icon type={text === 0x02 ? "folder" : "file"} />
                ),
                width: 30,
            },
            {
                title: (<FormattedMessage
                    id="gui.fileManager.columns.filename"
                    defaultMessage="文件名"

                />),
                dataIndex: 'name',
                onCell: (record) => ({
                    onClick: () => {
                        if (record.type === 0x02) {
                            this.getSummury(this.state.selectedExtension, record.path);
                        }
                    },
                }),
                render: (text, record, index) => (
                    <div className={styles.pathLink}>{text}</div>
                ),
                width: 200,
            },
            {
                title: (<FormattedMessage
                    id="gui.fileManager.columns.size"
                    defaultMessage="大小"

                />),
                align: 'right',
                dataIndex: 'size',
                render: (text, record, index) => {
                    if (!text) {
                        return '-';
                    }
                    let input = Number(text);
                    if (input < Math.pow(10, 4)) {
                        return (input.toString() + " B");
                    }
                    if (input < Math.pow(10, 7)) {
                        return (Math.floor(input / Math.pow(10, 3)).toString() + " KB");
                    }
                    if (input < Math.pow(10, 10)) {
                        return (Math.floor(input / Math.pow(10, 6)).toString() + " MB");
                    }
                    if (input < Math.pow(10, 13)) {
                        return (Math.floor(input / Math.pow(10, 9)).toString() + " GB");
                    }
                },
                width: 60
            },
            {
                align: 'center',
                dataIndex: 'path',
                onCell: (record) => ({
                    onClick: () => {
                        if (navigator.clipboard) {
                            navigator.clipboard.writeText(record.path);
                            this.openMessage('success', window.GetLocaleMsgs(document.documentElement.lang)['messageFileManagerCopyPathComplete']);
                        }
                    },
                }),
                render: (text, record, index) => (
                    <Button type="primary" icon="copy" size='small' shape='round'>
                        <FormattedMessage
                            id="gui.fileManager.columns.path.copy"
                            defaultMessage="复制路径"
                        />
                    </Button>
                ),
                width: 30
            },
            {
                align: 'center',
                dataIndex: 'id',
                onCell: (record) => ({
                    onClick: () => {
                        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
                        if (window.confirm(msgs['messageFileManagerDeleteConfirm']) === true) {
                            this.handleDelete(record.path);
                        }
                    },
                }),
                render: (text, record, index) => (
                    <Button type="danger" icon="delete" size='small' shape='round'>
                        <FormattedMessage
                            id="gui.fileManager.columns.id.delete"
                            defaultMessage="删除"
                        />
                    </Button>
                ),
                width: 30
            }
        ];
        const { Header, Footer, Content } = Layout;
        // const modalContentHeight = 390;
        // const modalHeadertHeight = 50;
        // const layoutHeaderHeight = 64;
        // const layoutFooterHeight = 48;
        // const layoutContentHeight = modalContentHeight - modalHeadertHeight - layoutHeaderHeight - layoutFooterHeight;
        const layoutContentHeight = 290;
        const extensions = this.props.extensions.map(e => {
            return (<Select.Option value={e.id}>{e.name}</Select.Option>)
        });
        return (<Modal
                    className={styles.modalContent}
                    contentLabel={<FormattedMessage
                        defaultMessage="管理设备上的文件"
                        id="gui.menuBar.manageFile"
                    />}
                    id={this.props.id}
                    onRequestClose={this.handleClose}
                >
                    <ConfigProvider locale={this.getLocale()}>
                        <Layout>
                            <Header style={{
                                backgroundColor: 'white'
                            }}>
                                <Row>
                                    <Col span={7}>
                                        <FormattedMessage
                                            defaultMessage="设备："
                                            id="gui.fileManager.extension"
                                        />
                                        <Select style={{ width: 100 }} onChange={this.handleChangeExtension}>
                                            {extensions}
                                        </Select>
                                    </Col>
                                    <Col span={10}>
                                        <FormattedMessage
                                            defaultMessage="路径："
                                            id="gui.fileManager.currentPath"
                                        />{this.state.currentPath}
                                    </Col>
                                    <Col span={7} style={{ textAlign: 'right' }}>
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            <Tooltip placement="top" title={<FormattedMessage
                                                defaultMessage="刷新"
                                                id="gui.fileManager.reload"
                                            />}>
                                                <Button icon="reload" onClick={this.handleReload}></Button>
                                            </Tooltip>
                                            <Tooltip placement="top" title={<FormattedMessage
                                                defaultMessage="返回上一级"
                                                id="gui.fileManager.folderUp"
                                            />}>
                                                <Button icon="arrow-up" onClick={this.handleFolderUp}></Button>
                                            </Tooltip>
                                            <Tooltip placement="top" title={<FormattedMessage
                                                defaultMessage="新建目录"
                                                id="gui.fileManager.add.folder"
                                            />}>
                                                <Button icon="folder-add" onClick={this.addFolder}></Button>
                                            </Tooltip>
                                            <Upload
                                                beforeUpload={this.handleBeforeUpload}
                                                className="hide-upload-list"
                                                disabled={this.props.visibleAlert === true}
                                            >
                                                <Tooltip placement="top" title={<FormattedMessage
                                                    defaultMessage="添加文件"
                                                    id="gui.fileManager.add.file"
                                                />}>
                                                    <Button icon="file-add"></Button>
                                                </Tooltip>
                                            </Upload>
                                        </div>
                                    </Col>
                                </Row>
                            </Header>
                            <Content>
                                <Table
                                    columns={fileResourceColumns}
                                    dataSource={this.props.items}
                                    onChange={this.handleTableChange}
                                    pagination={{
                                        ...this.props.pager,
                                        current: this.state.currentPage,
                                        onChange: this.handleChangePage,
                                        showQuickJumper: true,
                                        size: 'small'
                                    }}
                                    rowKey={'id'}
                                    style={{
                                        height: `${layoutContentHeight}px`
                                    }}
                                />
                            </Content>
                        </Layout>
                    </ConfigProvider>

                    {/* 进度 */}
                    {this.props.visibleAlert===true ? (
                        <div className={styles.progressArea}>
                            <Progress style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} 
                                type="circle" 
                                percent={this.props.uploadProgress}
                                width={60} 
                                strokeWidth={12} 
                                strokeColor='#3692f8'/>
                        </div>
                    ) : null}

                    <AModal
                        title={<FormattedMessage
                            defaultMessage="新建目录"
                            id="gui.fileManager.add.folder"
                        />}
                        className={styles.modalNewfolder}
                        visible={this.state.visibleModal}
                        onCancel={() => {
                            this.setState({ visibleModal: false })
                        }}
                        footer={null}
                    >
                        <Row gutter={16}>
                            <Col span={16}>
                                <Input addonBefore={<FormattedMessage
                                    defaultMessage="名称"
                                    id="gui.fileManager.add.folder.name"
                                />}
                                    maxLength={20}
                                    onChange={this.handleNewFolderNameChange}
                                    placeholder={window.GetLocaleMsgs(document.documentElement.lang)['messageFileManagerAddFolderLengthLimit']}
                                    value={this.state.newFolderName} />
                            </Col>
                            <Col span={8}>
                                <Button type="primary" onClick={this.handleFolderAdd}><FormattedMessage
                                    defaultMessage="提交"
                                    id="gui.fileManager.add.folder.submit"
                                /></Button>
                            </Col>
                        </Row>
                    </AModal>
                </Modal>
        );
    }
}

FileManager.propTypes = {
    currentPage: PropTypes.number,
    currentPath: PropTypes.string,
    fileResourceItems: PropTypes.array,
    // id: PropTypes.string,
    loadingFile: PropTypes.bool,
    newFolderName: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    selectedExtension: PropTypes.string,
    visible: PropTypes.bool,
    visibleModal: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    extensions: state.scratchGui.fileManager.extensions,
    itemCount: state.scratchGui.fileManager.itemCount,
    items: state.scratchGui.fileManager.items,
    pager: {
        pageSize: 5,
        total: state.scratchGui.fileManager.itemCount
    },
    reloadFlag: state.scratchGui.fileManager.reloadFlag,
    uploadProgress: state.scratchGui.fileManager.uploadProgress,
    version: state.scratchGui.fileManager.version,
    visibleAlert: state.scratchGui.fileManager.visibleAlert
});

const mapDispatchToProps = dispatch => ({
    onFileManagementClearItems: () => {
        dispatch(fileManagementClearItems());
    },
    onFileManagementUpdateUploadStatus: (status) => {
        dispatch(fileManagementUpdateUploadStatus(status));
    },
    onRequestClose: () => {
        dispatch(closeFileManager()); // 关闭当前弹窗
        dispatch(fileManagementClearItems());
    }
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(FileManager));
