const FILE_MANAGEMENT_ADD_EXTENSION = 'scratch-gui/file-manager/FILE_MANAGEMENT_ADD_EXTENSION';
const FILE_MANAGEMENT_CLEAR_ITEMS = 'scratch-gui/file-manager/FILE_MANAGEMENT_CLEAR_ITEMS';
const FILE_MANAGEMENT_RELOAD = 'scratch-gui/file-manager/FILE_MANAGEMENT_RELOAD';
const FILE_MANAGEMENT_RESPONSE = 'scratch-gui/file-manager/FILE_MANAGEMENT_RESPONSE';
const FILE_MANAGEMENT_UPLOAD = 'scratch-gui/file-manager/FILE_MANAGEMENT_UPLOAD';
const FILE_MANAGEMENT_UPDATE_UPLOAD_STATUS = 'scratch-gui/file-manager/FILE_MANAGEMENT_UPDATE_UPLOAD_STATUS';

const initialState = {
    current: 1,
    extensions: [],
    infoPath: '',
    itemCount: 0,
    items: [],
    reloadFlag: 0,
    version: 0,
    visibleAlert: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    if (!action)
        return state;
    switch (action.type) {
        case FILE_MANAGEMENT_ADD_EXTENSION:
            return Object.assign({}, state, {
                extensions: state.extensions.concat(action.extension)
            });
        case FILE_MANAGEMENT_CLEAR_ITEMS:
            state.items = [];
            return state;
        case FILE_MANAGEMENT_RELOAD:
            state.version = action.flag.version;
            state.reloadFlag = action.flag.reload;
            return state;
        case FILE_MANAGEMENT_RESPONSE:
            switch (action.response.command) {
                case 0x06:
                    // console.log(action.response.entity);
                    state.uploadProgress = action.response.entity;
                    state.visibleAlert = true;
                    break;
                case 0xd2:
                    if (action.response.success !== 0x01) {
                        console.error(action.response);
                        break;
                    }
                    state.version = action.response.version;
                    break;
                case 0xf2:
                    if (action.response.success !== 0x01) {
                        console.error(action.response);
                        break;
                    }
                    // console.log(action.response.entity);
                    state.items = [];
                    return Object.assign({}, state, {
                        itemCount: action.response.entity,
                        version: action.response.version
                    });
                case 0xf4:
                    if (action.response.success !== 0x01) {
                        console.error(action.response);
                        break;
                    }
                    // console.log(action.response.entity);
                    state.items.push({
                        ...action.response.entity
                    });
                    state.version = action.response.version;
                    break;
                case 0xf6:
                    if (action.response.success !== 0x01) {
                        console.error(action.response);
                        break;
                    }
                    state.version = action.response.version;
                    break;
                case 0xf8:
                    if (action.response.success !== 0x01) {
                        console.error(action.response);
                        break;
                    }
                    // console.log(action.response.entity);
                    const result = {
                        ...state,
                        items: state.items.map(i => {
                            if (i.path === action.response.entity.path) {
                                i.size = action.response.entity.size
                            }
                            return i;
                        }),
                        version: action.response.version
                    };
                    // console.log(result);
                    return result;
            }
            return state;
        case FILE_MANAGEMENT_UPDATE_UPLOAD_STATUS:
            state.visibleAlert = action.status;
            return state;
        case FILE_MANAGEMENT_UPLOAD:
            state.uploadProgress = action.uploading.progress;
            state.visibleAlert = action.uploading.visible;
            return state;
        default:
            return state;
    }
};

const fileManagementAddExtension = function (extension) {
    return {
        type: FILE_MANAGEMENT_ADD_EXTENSION,
        extension
    }
}

const fileManagementClearItems = function () {
    return {
        type: FILE_MANAGEMENT_CLEAR_ITEMS
    }
}

const fileManagementReload = function (flag) {
    return {
        type: FILE_MANAGEMENT_RELOAD,
        flag
    }
}

const fileManagementResponse = function (response) {
    return {
        type: FILE_MANAGEMENT_RESPONSE,
        response
    }
}

const fileManagementUpdateUploadStatus = function (status) {
    return {
        type: FILE_MANAGEMENT_UPDATE_UPLOAD_STATUS,
        status
    }
}

const fileManagementUpload = function (uploading) {
    return {
        type: FILE_MANAGEMENT_UPLOAD,
        uploading
    }
}

export {
    reducer as default,
    initialState as fileManagerInitialState,
    fileManagementAddExtension,
    fileManagementClearItems,
    fileManagementReload,
    fileManagementResponse,
    fileManagementUpdateUploadStatus,
    fileManagementUpload
};