exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #3692f8 */ /* #e9f2ff */ /* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .upload-code_upload-code_2I-iL {\r\n    /* width: 2rem; */\r\n    height: 2.4rem;\r\n    padding: 0.3rem 0.5rem;\r\n    border-radius: 0.25rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    cursor: pointer;\r\n} .upload-code_upload-code_2I-iL:hover {\r\n    background-color: hsla(215, 100%, 65%, 0.15);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"upload-code": "upload-code_upload-code_2I-iL",
	"uploadCode": "upload-code_upload-code_2I-iL"
};