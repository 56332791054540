const SET_FULL_SCREEN = 'scratch-gui/mode/SET_FULL_SCREEN';
const SET_PLAYER = 'scratch-gui/mode/SET_PLAYER';
const SET_STATE_HIDDEN = 'scratch-gui/mode/SET_STATE_HIDDEN';
const SET_STATE_LEFT = 'scratch-gui/mode/SET_STATE_LEFT';

const initialState = {
    showBranding: false,
    isFullScreen: false,
    isPlayerOnly: false,
    hasEverEnteredEditor: true,
    isStageHidden: false, // 是否隐藏舞台
    isStageLeft: false, //舞台在左边还是在右边
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    if(!action)
        return state;
    switch (action.type) {
    case SET_FULL_SCREEN:
        return Object.assign({}, state, {
            isFullScreen: action.isFullScreen
        });
    case SET_PLAYER:
        return Object.assign({}, state, {
            isPlayerOnly: action.isPlayerOnly,
            hasEverEnteredEditor: state.hasEverEnteredEditor || !action.isPlayerOnly
        });
    case SET_STATE_HIDDEN:
        return Object.assign({}, state, {
            isStageHidden: action.isStageHidden === undefined ? !state.isStageHidden : action.isStageHidden
        });
    case SET_STATE_LEFT:
        return Object.assign({}, state, {
            isStageLeft: action.isStageLeft === undefined ? !state.isStageLeft : action.isStageLeft
        });
    default:
        return state;
    }
};

const setFullScreen = function (isFullScreen) {
    return {
        type: SET_FULL_SCREEN,
        isFullScreen: isFullScreen
    };
};
const setPlayer = function (isPlayerOnly) {
    return {
        type: SET_PLAYER,
        isPlayerOnly: isPlayerOnly
    };
};

const setStageHidden = function (isStageHidden) {
    return {
        type: SET_STATE_HIDDEN,
        isStageHidden: isStageHidden
    };
};

const setStageLeft = function (left) {
    return {
        type: SET_STATE_LEFT,
        isStageLeft: left
    };
};

export {
    reducer as default,
    initialState as modeInitialState,
    setFullScreen,
    setPlayer,
    setStageHidden,
    setStageLeft,
};
