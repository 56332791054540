import {STAGE_DISPLAY_SIZES} from '../lib/layout-constants.js';
import {isMobile, isTablet} from 'react-device-detect';

const SET_STAGE_SIZE = 'scratch-gui/StageSize/SET_STAGE_SIZE';
const SET_STAGE_SIZE_MODE = 'scratch-gui/StageSize/SET_STAGE_SIZE_MODE';

const initialState = {
    stageSize: isMobile && !isTablet ? STAGE_DISPLAY_SIZES.small : STAGE_DISPLAY_SIZES.large,
    stageMode: '4:3',
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    if(!action)
        return state;
    switch (action.type) {
        case SET_STAGE_SIZE:
            return {
                ...state,
                stageSize: action.stageSize
            };
        case SET_STAGE_SIZE_MODE:
            return {
                ...state,
                stageMode: action.stageMode,
            };
        default:
            return state;
    }
};

const setStageSize = function (stageSize) {
    return {
        type: SET_STAGE_SIZE,
        stageSize: stageSize
    };
};

const setStageSizeMode = function (mode) {
    return {
        type: SET_STAGE_SIZE_MODE,
        stageMode: mode
    };
};

export {
    reducer as default,
    initialState as stageSizeInitialState,
    setStageSize,
    setStageSizeMode
};
