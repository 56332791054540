import queryString from 'query-string';

/**
 * Check if there's a value provided as a query parameter in the URL.
 * Return the corresponding value or null if not found.
 * @param {string} key the key of parsing the query string
 * @return {string} The value of the requested key or null if no key was
 * requested or found.
 */
const detectParamsKey = key => {
    const queryParams = queryString.parse(location.search);
    const value = Array.isArray(queryParams[key]) ?
        queryParams[key][0] :
        queryParams[key];
    if (typeof value === 'undefined') return null;
    return value;
};

export {
    detectParamsKey
};