exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.watermark_sprite-image_3vILs {\n    display: block;\n    width: 3rem;\n    height: 3rem;\n    -o-object-fit: contain;\n       object-fit: contain;\n    opacity: 0.5;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n", ""]);

// exports
exports.locals = {
	"sprite-image": "watermark_sprite-image_3vILs",
	"spriteImage": "watermark_sprite-image_3vILs"
};