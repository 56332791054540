exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".device-status-panel_device-status-panel_4P0OE {\r\n    font-size: 12px;\r\n    height: 100%;\r\n    margin-left: 2rem;\r\n    margin-top: 1rem;\r\n    -webkit-padding-start:0;\r\n            padding-inline-start:0;\r\n    text-align: left;\r\n    vertical-align: top;\r\n    width: 100%;\r\n}", ""]);

// exports
exports.locals = {
	"device-status-panel": "device-status-panel_device-status-panel_4P0OE",
	"deviceStatusPanel": "device-status-panel_device-status-panel_4P0OE"
};