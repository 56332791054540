import styles from '../containers/blocks.css';
/**
 * 工具栏自动隐藏
 */
export default function (Blockly){

  Blockly.Procedures.deleteProcedureDefCallback = function(procCode,
    definitionRoot) {
  var callers = Blockly.Procedures.getCallers(procCode,
      definitionRoot.workspace, definitionRoot, false /* allowRecursive */);
  if (callers.length > 0) {
    return false;
  }

  var workspace = definitionRoot.workspace;
  if(workspace.toolbox_ && workspace.toolbox_.flyout_)
    workspace.toolbox_.flyout_.setVisible(true)
  // Delete the whole stack.
  Blockly.Events.setGroup(true);
  definitionRoot.dispose();
  Blockly.Events.setGroup(false);

  // TODO (#1354) Update this function when '_' is removed
  // Refresh toolbox, so caller doesn't appear there anymore
  workspace.refreshToolboxSelection_();

  return true;
};

  Blockly.Flyout.prototype.show = function (xmlList) {
      this.workspace_.setResizesEnabled(false);
      this.hide();
      this.clearOldBlocks_();
    
      this.setVisible(true);
      // Create the blocks to be shown in this flyout.
      const contents = [];
      const gaps = [];
      this.permanentlyDisabled_.length = 0;
      for (var i = 0, xml; xml = xmlList[i]; i++) {
          // Handle dynamic categories, represented by a name instead of a list of XML.
          // Look up the correct category generation function and call that to get a
          // valid XML list.
          if (typeof xml === 'string') {
              const fnToApply = this.workspace_.targetWorkspace.getToolboxCategoryCallback(
                  xmlList[i]);
              const newList = fnToApply(this.workspace_.targetWorkspace);
              // Insert the new list of blocks in the middle of the list.
              // We use splice to insert at index i, and remove a single element
              // (the placeholder string). Because the spread operator (...) is not
              // available, use apply and concat the array.
              xmlList.splice.apply(xmlList, [i, 1].concat(newList));
              xml = xmlList[i];
          }
          if (xml.tagName) {
              const tagName = xml.tagName.toUpperCase();
              const default_gap = this.horizontalLayout_ ? this.GAP_X : this.GAP_Y;
              if (tagName == 'BLOCK') {
    
                  // We assume that in a flyout, the same block id (or type if missing id) means
                  // the same output BlockSVG.
    
                  // Look for a block that matches the id or type, our createBlock will assign
                  // id = type if none existed.
                  var id = xml.getAttribute('id') || xml.getAttribute('type');
                  const recycled = this.recycleBlocks_.findIndex(block => block.id === id);
    
    
                  // If we found a recycled item, reuse the BlockSVG from last time.
                  // Otherwise, convert the XML block to a BlockSVG.
                  var curBlock;
                  if (recycled > -1) {
                      curBlock = this.recycleBlocks_.splice(recycled, 1)[0];
                      const opacity = xml.getAttribute('opacity');
                      if (opacity) {
                          curBlock.setOpacity(opacity);
                      } else {
                          curBlock.setOpacity(1);
                      }
                  } else {
                      curBlock = Blockly.Xml.domToBlock(xml, this.workspace_);
                      let tooltip = xml.getAttribute('tooltip');
                      if(tooltip){
                        tooltip = tooltip.replace(/\\n/, '\n');
                        curBlock.setTooltip(Blockly.utils.replaceMessageReferences(tooltip));
                      }
                  }
                  if (curBlock.disabled) {
                      // Record blocks that were initially disabled.
                      // Do not enable these blocks as a result of capacity filtering.
                      this.permanentlyDisabled_.push(curBlock);
                  }
                  if(!curBlock.rendered){
                      continue;
                  }
                  contents.push({type: 'block', block: curBlock});
                  const gap = parseInt(xml.getAttribute('gap'), 10);
                  gaps.push(isNaN(gap) ? default_gap : gap);
              } else if (xml.tagName.toUpperCase() == 'SEP') {
                  // Change the gap between two blocks.
                  // <sep gap="36"></sep>
                  // The default gap is 24, can be set larger or smaller.
                  // This overwrites the gap attribute on the previous block.
                  // Note that a deprecated method is to add a gap to a block.
                  // <block type="math_arithmetic" gap="8"></block>
                  const newGap = parseInt(xml.getAttribute('gap'), 10);
                  // Ignore gaps before the first block.
                  if (!isNaN(newGap) && gaps.length > 0) {
                      gaps[gaps.length - 1] = newGap;
                  } else {
                      gaps.push(default_gap);
                  }
              } else if ((tagName == 'LABEL') && (xml.getAttribute('showStatusButton') == 'true')) {
                  var curButton = new Blockly.FlyoutExtensionCategoryHeader(this.workspace_,
                      this.targetWorkspace_, xml);
                      // 修正 显示位置
                  // curButton.flyoutWidth_ = this.DEFAULT_WIDTH;
                  contents.push({type: 'button', button: curButton});
                  gaps.push(default_gap);
              } else if (tagName == 'BUTTON' || tagName == 'LABEL') {
                  // Labels behave the same as buttons, but are styled differently.
                  const isLabel = tagName == 'LABEL';
                  var curButton = new Blockly.FlyoutButton(this.workspace_,
                      this.targetWorkspace_, xml, isLabel);
                  contents.push({type: 'button', button: curButton});
                  gaps.push(default_gap);
              }
          }
      }
    
      this.emptyRecycleBlocks_();
    
      this.layout_(contents, gaps);
    
      // IE 11 is an incompetent browser that fails to fire mouseout events.
      // When the mouse is over the background, deselect all blocks.
      const deselectAll = function () {
          const topBlocks = this.workspace_.getTopBlocks(false);
          for (var i = 0, block; block = topBlocks[i]; i++) {
              block.removeSelect();
          }
      };
    
      this.listeners_.push(Blockly.bindEvent_(this.svgBackground_, 'mouseover',
          this, deselectAll));
    
      this.workspace_.setResizesEnabled(true);
      this.reflow();
    
      // Correctly position the flyout's scrollbar when it opens.
      this.position();
    
      this.reflowWrapper_ = this.reflow.bind(this);
      this.workspace_.addChangeListener(this.reflowWrapper_);
    
      this.recordCategoryScrollPositions_();
  };

  //实现reflowInternal_方法
  Blockly.VerticalFlyout.prototype.reflowInternal_ = function(blocks) {
    this.flyoutWidth_ = 0;
    for (var i = 0, block; block = blocks[i]; i++) {
      this.flyoutWidth_ = Math.max(this.flyoutWidth_, block.getHeightWidth().width);
    }
    if(this.flyoutWidth_ < this.getWidth()){
      this.flyoutWidth_ = this.getWidth();
    }
    if (this.parentToolbox_) {
      // the category menu (60)
      this.parentToolbox_.width = this.getWidth() + 60;
    }
  }

  Blockly.Flyout.prototype.setVisible = function(visible) {
    var visibilityChanged = (visible != this.isVisible());
  
    this.isVisible_ = visible;
    if (visibilityChanged) {
      if(this.flyoutHeight_ != window.innerHeight && this.isVisible_){
        this.position();//窗口变化时， 重新计算大小
        this.flyoutHeight_ = window.innerHeight;
      }
      this.updateDisplay_();
    }
  };

  Blockly.Flyout.prototype.updateDisplay_ = function() {
    var show = true;
    if (!this.containerVisible_) {
      show = false;
    } else {
      show = this.isVisible();
    }
    this.svgGroup_.style.display = show ? 'block' : 'none';
    // Update the scrollbar's visiblity too since it should mimic the
    // flyout's visibility.
    this.scrollbar_.setContainerVisible(show);
    if (show) {
      this.svgGroup_.classList.add(`${styles['flyout-open']}`);
      this.svgGroup_.classList.remove(`${styles['flyout-close']}`);
    } else {
      this.svgGroup_.classList.add(`${styles['flyout-close']}`);
      this.svgGroup_.classList.remove(`${styles['flyout-open']}`);
    }
  };

  Blockly.Block.prototype.dispose = function(healStack) {
      if (!this.workspace) {
        // Already deleted.
        return;
      }
      // Terminate onchange event calls.
      if (this.onchangeWrapper_) {
        this.workspace.removeChangeListener(this.onchangeWrapper_);
      }
      this.unplug(healStack);
      if (Blockly.Events.isEnabled() && !this.workspace.isFlyout) {//是flayout就不发送删除事件，因为不需要undo
        Blockly.Events.fire(new Blockly.Events.BlockDelete(this));
      }
      Blockly.Events.disable();
    
      try {
        // This block is now at the top of the workspace.
        // Remove this block from the workspace's list of top-most blocks.
        if (this.workspace) {
          this.workspace.removeTopBlock(this);
          // Remove from block database.
          delete this.workspace.blockDB_[this.id];
          this.workspace = null;
        }
    
        // Just deleting this block from the DOM would result in a memory leak as
        // well as corruption of the connection database.  Therefore we must
        // methodically step through the blocks and carefully disassemble them.
    
        if (Blockly.selected == this) {
          Blockly.selected = null;
        }
    
        // First, dispose of all my children.
        for (var i = this.childBlocks_.length - 1; i >= 0; i--) {
          this.childBlocks_[i].dispose(false);
        }
        // Then dispose of myself.
        // Dispose of all inputs and their fields.
        for (var i = 0, input; input = this.inputList[i]; i++) {
          input.dispose();
        }
        this.inputList.length = 0;
        // Dispose of any remaining connections (next/previous/output).
        var connections = this.getConnections_(true);
        for (var i = 0; i < connections.length; i++) {
          var connection = connections[i];
          if (connection.isConnected()) {
            connection.disconnect();
          }
          connections[i].dispose();
        }
      } finally {
        Blockly.Events.enable();
      }
    };

  Blockly.init_ = function(mainWorkspace) {
      var options = mainWorkspace.options;
      var svg = mainWorkspace.getParentSvg();
    
      // Suppress the browser's context menu.
      Blockly.bindEventWithChecks_(svg.parentNode, 'contextmenu', null,
          function(e) {
            if (!Blockly.utils.isTargetInput(e)) {
              e.preventDefault();
            }
          });
    
      var workspaceResizeHandler = Blockly.bindEventWithChecks_(window, 'resize',
          null,
          function() {
            // 积木调起软键盘后马上被收起，无法进行输入
            if(document.activeElement){
              if (/input|textarea/i.test(document.activeElement.tagName)) {
                  document.activeElement.dispatchEvent(new Event('input'))
                  document.activeElement.scrollIntoView({block:"center", behavior:'smooth'})
                  return
                }
            }

            Blockly.hideChaffOnResize(true);
            Blockly.svgResize(mainWorkspace);
           if(mainWorkspace.toolbox_ && mainWorkspace.toolbox_.flyout_){
              const flyout = mainWorkspace.toolbox_.flyout_;
              flyout.reflow();
           }
        });
      mainWorkspace.setResizeHandlerWrapper(workspaceResizeHandler);
    
      Blockly.inject.bindDocumentEvents_();
    
      if (options.languageTree) {
        if (mainWorkspace.toolbox_) {
          mainWorkspace.toolbox_.init(mainWorkspace);
        } else if (mainWorkspace.flyout_) {
          // Build a fixed flyout with the root blocks.
          mainWorkspace.flyout_.init(mainWorkspace);
          mainWorkspace.flyout_.show(options.languageTree.childNodes);
          mainWorkspace.flyout_.scrollToStart();
          // Translate the workspace to avoid the fixed flyout.
          if (options.horizontalLayout) {
            mainWorkspace.scrollY = mainWorkspace.flyout_.height_;
            if (options.toolboxPosition == Blockly.TOOLBOX_AT_BOTTOM) {
              mainWorkspace.scrollY *= -1;
            }
          } else {
            mainWorkspace.scrollX = mainWorkspace.flyout_.width_;
            if (options.toolboxPosition == Blockly.TOOLBOX_AT_RIGHT) {
              mainWorkspace.scrollX *= -1;
            }
          }
          mainWorkspace.translate(mainWorkspace.scrollX, mainWorkspace.scrollY);
        }
      }
    
      if (options.hasScrollbars) {
        mainWorkspace.scrollbar = new Blockly.ScrollbarPair(mainWorkspace);
        mainWorkspace.scrollbar.resize();
      }
    
      // Load the sounds.
      if (options.hasSounds) {
        Blockly.inject.loadSounds_(options.pathToMedia, mainWorkspace);
      }
  };

    Blockly.WorkspaceSvg.getTopLevelWorkspaceMetrics_ = function() {
      var toolboxDimensions;
      if (this && this.getFlyout() && this.getFlyout().autoClose) {
        if(this.toolbox_){
          toolboxDimensions = this.toolbox_.getClientRect();
        }
        if(!toolboxDimensions){
          toolboxDimensions = Blockly.WorkspaceSvg.getDimensionsPx_(this.toolbox_);
        }
      } else {
        toolboxDimensions = Blockly.WorkspaceSvg.getDimensionsPx_(this.toolbox_);
      }
          
      var flyoutDimensions =
          Blockly.WorkspaceSvg.getDimensionsPx_(this.flyout_);
    
      // Contains height and width in CSS pixels.
      // svgSize is equivalent to the size of the injectionDiv at this point.
      var svgSize = Blockly.svgSize(this.getParentSvg());
      if (this.toolbox_) {
        if (this.toolboxPosition == Blockly.TOOLBOX_AT_TOP ||
            this.toolboxPosition == Blockly.TOOLBOX_AT_BOTTOM) {
          svgSize.height -= toolboxDimensions.height;
        } else if (this.toolboxPosition == Blockly.TOOLBOX_AT_LEFT ||
            this.toolboxPosition == Blockly.TOOLBOX_AT_RIGHT) {
          svgSize.width -= toolboxDimensions.width;
        }
      }
    
      // svgSize is now the space taken up by the Blockly workspace, not including
      // the toolbox.
      var contentDimensions =
          Blockly.WorkspaceSvg.getContentDimensions_(this, svgSize);
    
      var absoluteLeft = 0;
      if (this.toolbox_ && this.toolboxPosition == Blockly.TOOLBOX_AT_LEFT) {
        absoluteLeft = toolboxDimensions.width;
      }
      var absoluteTop = 0;
      if (this.toolbox_ && this.toolboxPosition == Blockly.TOOLBOX_AT_TOP) {
        absoluteTop = toolboxDimensions.height;
      }
    
      var metrics = {
        contentHeight: contentDimensions.height,
        contentWidth: contentDimensions.width,
        contentTop: contentDimensions.top,
        contentLeft: contentDimensions.left,
    
        viewHeight: svgSize.height,
        viewWidth: svgSize.width,
        viewTop: -this.scrollY,   // Must be in pixels, somehow.
        viewLeft: -this.scrollX,  // Must be in pixels, somehow.
    
        absoluteTop: absoluteTop,
        absoluteLeft: absoluteLeft,
    
        toolboxWidth: toolboxDimensions.width,
        toolboxHeight: toolboxDimensions.height,
    
        flyoutWidth: flyoutDimensions.width,
        flyoutHeight: flyoutDimensions.height,
    
        toolboxPosition: this.toolboxPosition
      };
      return metrics;
    };

    // 优化定位
    Blockly.WorkspaceSvg.prototype.topOnBlock = function(id) {
        if (!this.scrollbar) {
          console.warn('Tried to scroll a non-scrollable workspace.');
          return;
        }
      
        var block = this.getBlockById(id);
        if (!block) {
          return;
        }
      
        // XY is in workspace coordinates.
        var xy = block.getRelativeToSurfaceXY();
        // Height/width is in workspace units.
        var heightWidth = block.getHeightWidth();
      
        // Find the enter of the block in workspace units.
        // var blockCenterY = xy.y + heightWidth.height / 2;
        var blockCenterY = xy.y + 20; // 固定整理积木中心点为第一块积木自顶向下高度为20的地方，避免积木块太长造成顶部超出workspace的情况发生
      
        // In RTL the block's position is the top right of the block, not top left.
        var multiplier = this.RTL ? -1 : 1;
        // var blockCenterX = xy.x + (multiplier * heightWidth.width / 2);
        var blockCenterX = xy.x; // 调整x轴位置
      
        // Workspace scale, used to convert from workspace coordinates to pixels.
        var scale = this.scale;
      
        // Center in pixels.  0, 0 is at the workspace origin.  These numbers may
        // be negative.
        var pixelX = blockCenterX * scale;
        var pixelY = blockCenterY * scale;
      
        var metrics = this.getMetrics();
      
        // Scrolling to here would put the block in the top-left corner of the
        // visible workspace.
        var scrollToBlockX = pixelX - metrics.contentLeft;
        var scrollToBlockY = pixelY - metrics.contentTop;
      
        // viewHeight and viewWidth are in pixels.
        var halfViewWidth = metrics.viewWidth / 2;
        var halfViewHeight = metrics.viewHeight / 6.5;
      
        // Put the block in the center of the visible workspace instead.
        var scrollToCenterX = scrollToBlockX - halfViewWidth;
        var scrollToCenterY = scrollToBlockY - halfViewHeight;
      
        Blockly.hideChaff();
        this.scrollbar.set(scrollToCenterX, scrollToCenterY);
      };


    //突出显示
    const oldSetMetrics_  = Blockly.VerticalFlyout.prototype.setMetrics_;
    Blockly.VerticalFlyout.prototype.setMetrics_ = function(xyRatio) {
      oldSetMetrics_.apply(this, [xyRatio]);
      if(!this.flyoutWidth_){
        return;
      }
      this.clipRect_.setAttribute('width', this.flyoutWidth_ + 'px');
    };
}