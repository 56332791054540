exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".blocklyFlyout {\r\n    -webkit-transition: 0.3s;\r\n    transition: 0.3s;\r\n    /* border-right:none !important; */\r\n\r\n}\r\n\r\n.blocklyFlyout.blocks_flyout-open_KWXmA {\r\n    /* max-width: 16rem; */\r\n}\r\n\r\n@media screen and (max-width: 1536px) {\r\n    .blocklyFlyout.blocks_flyout-open_KWXmA {\r\n        overflow: visible;\r\n    }\r\n}\r\n\r\n.blocklyFlyout:hover {\r\n    overflow: visible;\r\n}\r\n\r\n.blocklyFlyout.blocks_flyout-close_uHx7q {\r\n    display: block !important;\r\n    pointer-events: none;\r\n    opacity: 0 !important;\r\n    z-index: 1;\r\n    max-width: 4.59rem;\r\n    -webkit-transform: translate(0, 0) !important;\r\n        -ms-transform: translate(0, 0) !important;\r\n            transform: translate(0, 0) !important;\r\n}\r\n\r\n.blocklyTooltipDiv{\r\n    padding: 0.5rem;\r\n    border-radius: 0.4rem;\r\n}\r\n\r\n.blocks_toolTipInfo_2mjHW{\r\n    position: absolute;\r\n    width: 1rem;\r\n    height: 1rem;\r\n    margin-top:6px;\r\n    margin-left: 5px;\r\n    top: 0;\r\n    left: 0;\r\n}\r\n\r\n.blocklyTooltipDiv div{\r\n    margin-left:1.1rem;\r\n}\r\n\r\n.blocklyFlyoutBackground {\r\n    fill: #c1d7ff;\r\n    fill-opacity: .35;\r\n    /* stroke: hsla(0, 0%, 0%, 0.15);\r\n    stroke-width: 1px; */\r\n}\r\n\r\n.globalLabelStyle>.blocklyFlyoutLabelText {\r\n    font-weight: normal;\r\n}", ""]);

// exports
exports.locals = {
	"flyout-open": "blocks_flyout-open_KWXmA",
	"flyoutOpen": "blocks_flyout-open_KWXmA",
	"flyout-close": "blocks_flyout-close_uHx7q",
	"flyoutClose": "blocks_flyout-close_uHx7q",
	"toolTipInfo": "blocks_toolTipInfo_2mjHW"
};