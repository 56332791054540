const PREFERENCES_MODAL_CHANGE_USELINK = 'scratch-gui/preferences-modal/PREFERENCES_MODAL_CHANGE_USELINK';
const PREFERENCES_MODAL_GET_PREFERENCES = 'scratch-gui/preferences-modal/PREFERENCES_MODAL_GET_PREFERENCES';

const initialState = {
    useLink: true
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    if (!action)
        return state;
    switch (action.type) {
        case PREFERENCES_MODAL_CHANGE_USELINK:
            return Object.assign({}, state, {
                useLink: !state.useLink,
            });
        case PREFERENCES_MODAL_GET_PREFERENCES:
            let ul;
            if(window.electronAPI){
                //防止配置文件丢失
                if(!window.preferences){
                    ul=window.env.useLink;
                    window.preferences={
                        value: {
                            useLink: ul
                        }
                    };
                }else{
                    ul=window.preferences.value.useLink;
                }
                //尝试再次获取
                window.electronAPI.send("get-preferences", null);
            }else{
                ul=window.CookieManager.getCookie('useLink');
                if(!ul){
                    ul=window.env.useLink;
                    window.CookieManager.setCookie('useLink', ul);
                }else{
                    ul=(ul.toLowerCase()==='true');
                }
            }
            // console.log(`Get preferences: ${ul}`);
            state.useLink=ul;
            return state;
        default:
            return state;
    }
};

const preferencesModalChangeUselink = function (status) {
    return {
        type: PREFERENCES_MODAL_CHANGE_USELINK,
        status
    }
}

const preferencesModalGetPreferences = function (status) {
    return {
        type: PREFERENCES_MODAL_GET_PREFERENCES,
        status
    }
}

export {
    reducer as default,
    initialState as preferencesModalInitialState,
    preferencesModalChangeUselink,
    preferencesModalGetPreferences
};