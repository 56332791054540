import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import Box from '../box/box.jsx';
import styles from './blocks.css';
import iconDelete from './icon-delete.svg';
import {isMobile} from 'react-device-detect';

const BlocksComponent = props => {
    const {
        containerRef,
        dragOver,
        ...componentProps
    } = props;
    return (
        <Box
            className={classNames(styles.blocks, {
                [styles.dragOver]: dragOver,
                [styles.isMobile]: isMobile
            })}
            {...componentProps}
            componentRef={containerRef}
        >
            <div id='deleteBlocksTip' className={classNames(styles.deleteBlocksTip, 'blocklyToolboxDelete')}>
                <img
                    src={iconDelete}
                    className={styles.trashImg}
                />
            </div>
        </Box>
    );
};
BlocksComponent.propTypes = {
    containerRef: PropTypes.func,
    dragOver: PropTypes.bool
};
export default BlocksComponent;
