exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".paint-editor-wrapper_close-button_vGCBH {\r\n    position: absolute;\r\n    right: 1rem;\r\n    top: 1rem;\r\n}\r\n\r\n[class^='asset-panel_wrapper_'] {\r\n    height: 100vh;\r\n}\r\n\r\n[class^='asset-panel_detail-area_'] {\r\n    overflow: auto;\r\n}\r\n\r\n@media only screen and (max-height: 516px) {\r\n    [class^='paint-editor_editor-container_'] {\r\n        height: auto;\r\n    }\r\n}", ""]);

// exports
exports.locals = {
	"close-button": "paint-editor-wrapper_close-button_vGCBH",
	"closeButton": "paint-editor-wrapper_close-button_vGCBH"
};