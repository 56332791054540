import classNames from 'classnames';
import PropTypes from 'prop-types';
import {defineMessages, injectIntl, intlShape} from 'react-intl';
import React from 'react';
import ExtensionStatus from './extension-status.jsx';

import styles from './extension-control.css';

const Controls = function (props) {
    const {
        vm,
        items,
        className,
        onStatusClick,
        pathToMedia,
        ...componentProps
    } = props;
    
    return (
        <div
            className={classNames(styles.controlsContainer, className)}
            {...componentProps}
        >
            {items.map(item =>(
                <ExtensionStatus
                    vm = {vm}
                    status={vm.getPeripheralIsConnected(item.id)?
                        pathToMedia + 'status-ready.svg':pathToMedia + 'status-not-ready.svg'}
                    onClick={()=>{
                        onStatusClick(item.id);
                    }}
                    blockIconURI={item.blockIconURI}
                />
            ))}
        </div>
    );
};

Controls.propTypes = {
    items: PropTypes.array,
    onStatusClick: PropTypes.func.isRequired,
};

Controls.defaultProps = {
    items: [],
};

export default injectIntl(Controls);
