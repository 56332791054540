import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import VM from 'scratch-vm';
import { injectIntl, intlShape } from 'react-intl';
import { detectParamsKey } from '../lib/url-utils.js';
import axios from "axios";

import ErrorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {
    getIsError,
    getIsShowingProject,
    setAxiosProjectStatus,
} from '../reducers/project-state';
import {
    activateTab,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX

    , TEST_TAB_INDEX
} from '../reducers/editor-tab';

import {
    closeCostumeLibrary,
    closeBackdropLibrary,
    closeTelemetryModal,
    openExtensionLibrary
} from '../reducers/modals';

import FontLoaderHOC from '../lib/font-loader-hoc.jsx';
import LocalizationHOC from '../lib/localization-hoc.jsx';
import ProjectFetcherHOC from '../lib/project-fetcher-hoc.jsx';
import TitledHOC from '../lib/titled-hoc.jsx';
import ProjectSaverHOC from '../lib/project-saver-hoc.jsx';
import QueryParserHOC from '../lib/query-parser-hoc.jsx';
import storage from '../lib/storage';
import vmListenerHOC from '../lib/vm-listener-hoc.jsx';
import vmManagerHOC from '../lib/vm-manager-hoc.jsx';
import cloudManagerHOC from '../lib/cloud-manager-hoc.jsx';

import GUIComponent from '../components/gui/gui.jsx';
import { setIsScratchDesktop } from '../lib/isScratchDesktop.js';
import { setStageHidden } from '../reducers/mode';
import { OnBack } from '../lib/js-bridge.js';

class GUI extends React.Component {
    componentDidMount() {
        setIsScratchDesktop(this.props.isScratchDesktop);
        this.props.onStorageInit(storage);
        this.props.onVmInit(this.props.vm);
        window.ScratchGui = this;
    }
    componentDidUpdate(prevProps) {
        if (this.props.projectId !== prevProps.projectId && this.props.projectId !== null) {
            this.props.onUpdateProjectId(this.props.projectId);
        }
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            // this only notifies container when a project changes from not yet loaded to loaded
            // At this time the project view in www doesn't need to know when a project is unloaded
            this.props.onProjectLoaded();
        }
    }

    render() {
        if (this.props.isError) {
            throw new Error(
                `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`);
        }
        const {
            /* eslint-disable no-unused-vars */
            assetHost,
            cloudHost,
            error,
            isError,
            isScratchDesktop,
            isShowingProject,
            onProjectLoaded,
            onStorageInit,
            onUpdateProjectId,
            onVmInit,
            projectHost,
            projectId,
            /* eslint-enable no-unused-vars */
            children,
            fetchingProject,
            isLoading,
            loadingStateVisible,
            // // 是否正在下载sb3，暂时不用官方那一套，自己用axios下载吧
            isDownloadingProject,
            ...componentProps
        } = this.props;
        return (
            <GUIComponent
                loading={fetchingProject || isLoading || loadingStateVisible || isDownloadingProject}
                onBackButtonClick={OnBack}
                {...componentProps}
            >
                {children}
            </GUIComponent>
        );
    }
}

GUI.propTypes = {
    assetHost: PropTypes.string,
    children: PropTypes.node,
    cloudHost: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    fetchingProject: PropTypes.bool,
    isDownloadingProject: PropTypes.bool,
    intl: intlShape,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isScratchDesktop: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingStateVisible: PropTypes.bool,
    onProjectLoaded: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onStorageInit: PropTypes.func,
    onUpdateProjectId: PropTypes.func,
    onVmInit: PropTypes.func,
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    telemetryModalVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    isStageHidden: PropTypes.bool,
    isStageLeft: PropTypes.bool,
    toggleStageHidden: PropTypes.func,
    stageMode: PropTypes.string,
};

GUI.defaultProps = {
    isScratchDesktop: false,
    onStorageInit: storageInstance => storageInstance.addOfficialScratchWebStores(),
    onProjectLoaded: () => {
        // 看看是否使用link，不使用传0，使用传1，默认是使用link的
        let link = detectParamsKey('link');
        if (link === '0') {
            ScratchGui.props.vm.runtime.useLink = false;
        }
        if (navigator.userAgent.toLowerCase().indexOf(' electron/') > -1 && window.electronAPI) {
            window.electronAPI.send("get-preferences", null);
            window.electronAPI.receive("get-preferences-reply", (data) => {
                const pf = JSON.parse(data);
                window.preferences = pf;
                ScratchGui.props.vm.runtime.useLink = pf.value.useLink;
            });
            window.electronAPI.receive("set-preferences-reply", (err) => {
                if (err) {
                    console.error(err);
                }
            });
            window.electronAPI.receive("exec-link-reply", ({ error, errmsg }) => {
                if (error) {
                    message.error(errmsg);
                }
            });
        }
        else{
            let ul=window.CookieManager.getCookie('useLink');
            if(ul===null){
                ul=window.env.useLink;
                window.CookieManager.setCookie('useLink', ul);
            }else{
                ul=(ul.toLowerCase()==='true');
            }
            ScratchGui.props.vm.runtime.useLink=ul;
        }

        // 加载某一个扩展
        let extId = detectParamsKey('ext');
        if (extId) {
            ScratchGui.props.vm.extensionManager.loadExtensionURL(extId);
            // 把扩展入口屏蔽掉
        }

        // 加载远程sb3
        let sb3Url = detectParamsKey('sb3');
        if (sb3Url) {
            axios({
                method: 'get',
                url: sb3Url,
                responseType: "arraybuffer",
                onDownloadProgress: (evt) => {
                    console.log(evt.loaded / evt.total);
                }
            }).then(res => {
                ScratchGui.props.vm.loadProject(res.data);
                ScratchGui.props.onAxiosProjectStatus(false);
            }).catch(err => {
                console.log(err);
                ScratchGui.props.onAxiosProjectStatus(false);
            });
        }
    },
    onUpdateProjectId: () => { },
    onVmInit: (/* vm */) => { },
    isStageHidden: false,
    isStageLeft: false,
    stageMode: '4:3'
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        alertsVisible: state.scratchGui.alerts.visible,
        backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
        blocksTabVisible: state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
        cardsVisible: state.scratchGui.cards.visible,
        connectionModalVisible: state.scratchGui.modals.connectionModal,
        costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
        costumesTabVisible: state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
        error: state.scratchGui.projectState.error,
        extensionSelectorVisible: state.scratchGui.modals.extensionSelector,
        fileManagerVisible: state.scratchGui.modals.fileManager,
        isError: getIsError(loadingState),
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        loadingStateVisible: state.scratchGui.modals.loadingProject,
        preferencesVisible: state.scratchGui.modals.preferences,
        projectId: state.scratchGui.projectState.projectId,
        soundsTabVisible: state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,
        targetIsStage: (
            state.scratchGui.targets.stage &&
            state.scratchGui.targets.stage.id === state.scratchGui.targets.editingTarget
        ),
        telemetryModalVisible: state.scratchGui.modals.telemetryModal,
        tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
        vm: state.scratchGui.vm,
        isStageHidden: state.scratchGui.mode.isStageHidden,
        isStageLeft: state.scratchGui.mode.isStageLeft,
        stageMode: state.scratchGui.stageSize.stageMode,
        isDownloadingProject: state.scratchGui.projectState.isDownloadingProject
    };
};

const mapDispatchToProps = dispatch => ({
    onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
    onActivateTab: tab => dispatch(activateTab(tab)),
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
    onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
    onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal())

    , onActivateTestTab: () => dispatch(activateTab(TEST_TAB_INDEX)),
    toggleStageHidden: () => dispatch(setStageHidden()),
    onAxiosProjectStatus: (isDownloading) => dispatch(setAxiosProjectStatus(isDownloading))
});

const ConnectedGUI = injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(GUI));

// note that redux's 'compose' function is just being used as a general utility to make
// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
// ability to compose reducers.
const WrappedGui = compose(
    LocalizationHOC,
    ErrorBoundaryHOC('Top Level App'),
    FontLoaderHOC,
    QueryParserHOC,
    ProjectFetcherHOC,
    TitledHOC,
    ProjectSaverHOC,
    vmListenerHOC,
    vmManagerHOC,
    cloudManagerHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;
