exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".extension-control_controls-container_1Iqwo {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n}\n", ""]);

// exports
exports.locals = {
	"controls-container": "extension-control_controls-container_1Iqwo",
	"controlsContainer": "extension-control_controls-container_1Iqwo"
};