import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ConnectionModalComponent, { PHASES } from '../components/connection-modal/connection-modal.jsx';
import VM from 'scratch-vm';
import analytics from '../lib/analytics';
import extensionData from '../lib/libraries/extensions/index.jsx';
import { connect } from 'react-redux';
import { closeConnectionModal } from '../reducers/modals';
import { setCommMode } from '../reducers/connection-modal';

import { message } from 'antd';

class ConnectionModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleScanning',
            'handleCancel',
            'handleConnected',
            'handleConnecting',
            'handleDisconnect',
            'handleError',
            'handleHelp'

            , 'handleChangeAutoReconnectEnabled'
        ]);
        this.state = {
            extension: extensionData.find(ext => ext.extensionId === props.extensionId),
            flagScan: '',
            listeningExecLink: false,
            phase: props.vm.getPeripheralIsConnected(props.extensionId) ?
                PHASES.connected : PHASES.scanning
        };
    }
    componentDidMount() {
        this.props.vm.on('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.on('PERIPHERAL_REQUEST_ERROR', this.handleError);
        if (window.electronAPI && navigator.userAgent.toLowerCase().indexOf(' electron/') > -1) {
            if(this.state.listeningExecLink===true){
                return;
            }
            this.setState({
                listeningExecLink: true
            });
        }
    }
    componentWillUnmount() {
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.removeListener('PERIPHERAL_REQUEST_ERROR', this.handleError);
    }
    handleScanning() {
        this.setState({
            phase: PHASES.scanning
        });
    }
    handleConnecting(peripheralId) {
        this.props.vm.connectPeripheral(this.props.extensionId, peripheralId);
        this.setState({
            phase: PHASES.connecting
        });
        analytics.event({
            category: 'extensions',
            action: 'connecting',
            label: this.props.extensionId
        });
    }
    handleDisconnect() {
        try {
            this.props.vm.disconnectPeripheral(this.props.extensionId);
        } finally {
            this.props.onCancel();
            // 清除历史连接设备
            this.props.vm.runtime.peripheralExtensions[this.props.extensionId]._historyDeviceId = null;
        }
    }
    handleCancel() {
        try {
            // If we're not connected to a peripheral, close the websocket so we stop scanning.
            if (!this.props.vm.getPeripheralIsConnected(this.props.extensionId)) {
                this.props.vm.disconnectPeripheral(this.props.extensionId);
            }
        } finally {
            // Close the modal.
            this.props.onCancel();
        }
    }
    handleError() {
        // Assume errors that come in during scanning phase are the result of not
        // having scratch-link installed.
        if (this.state.phase === PHASES.scanning || this.state.phase === PHASES.unavailable) {
            this.setState({
                phase: PHASES.unavailable
            });
        } else {
            this.setState({
                phase: PHASES.error
            });
            analytics.event({
                category: 'extensions',
                action: 'connecting error',
                label: this.props.extensionId
            });
        }
    }
    handleConnected() {
        this.setState({
            phase: PHASES.connected
        });
        analytics.event({
            category: 'extensions',
            action: 'connected',
            label: this.props.extensionId
        });

        // 连接成功，自动把窗口关闭
        this.handleCancel();
    }
    handleHelp() {
        window.open(this.state.extension.helpLink, '_blank');
        analytics.event({
            category: 'extensions',
            action: 'help',
            label: this.props.extensionId
        });
    }
    handleChangeAutoReconnectEnabled(checked) {
        // console.log(`set ${this.props.extensionId}.AutoReconnectEnabled=${checked.toString()}`);
        if (this.props.vm.runtime.peripheralExtensions[this.props.extensionId].parameters) {
            this.props.vm.runtime.peripheralExtensions[this.props.extensionId].parameters.autoReconnectEnabled = checked;
            if (checked === false) {
                this.props.vm.runtime.peripheralExtensions[this.props.extensionId].parameters.autoReconnectPeripheralId = '';
            }
            //更改此参数用于触发刷新窗体内容
            this.setState({
                flagScan: Math.random()
            });
        }
    }
    handleStartLink() {
        if (window.electronAPI && navigator.userAgent.toLowerCase().indexOf(' electron/') > -1) {
            console.log('send exec-link');
            window.electronAPI.send("exec-link", null);
        }
    }
    handleToggle = (checked) => {
        // 将状态保存到 runtime 
        this.props.vm.runtime.commBle = !!checked;
        // 清除历史连接设备
        this.props.vm.runtime.peripheralExtensions[this.props.extensionId]._historyDeviceId = null;
        // 切换的时候需要现将当前连接断开
        try {
            this.props.vm.disconnectPeripheral(this.props.extensionId);
            this.handleScanning();
        } finally {
            this.props.onSetCommMode(checked)
        }
    }
    render() {
        return (
            <ConnectionModalComponent
                connectingMessage={this.state.extension && this.state.extension.connectingMessage}
                connectionIconURL={this.state.extension && this.state.extension.connectionIconURL}
                connectionSmallIconURL={this.state.extension && this.state.extension.connectionSmallIconURL}
                connectionTipIconURL={this.state.extension && this.state.extension.connectionTipIconURL}
                extensionId={this.props.extensionId}
                flagScan={this.state.flagScan}
                isCommBle={this.props.isCommBle}
                onToggle={this.handleToggle}
                name={this.state.extension && this.state.extension.name}
                phase={this.state.phase}
                title={this.props.extensionId}
                useAutoScan={this.state.extension && this.state.extension.useAutoScan}
                vm={this.props.vm}
                onCancel={this.handleCancel}
                onChangeAutoReconnectEnabled={this.handleChangeAutoReconnectEnabled}
                onConnected={this.handleConnected}
                onConnecting={this.handleConnecting}
                onDisconnect={this.handleDisconnect}
                // onHelp={this.handleHelp}
                onScanning={this.handleScanning}
                onStartLink={this.handleStartLink}
            />
        );
    }
}

ConnectionModal.propTypes = {
    extensionId: PropTypes.string.isRequired,
    isCommBle: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSetCommMode: PropTypes.func.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    extensionId: state.scratchGui.connectionModal.extensionId,
    isCommBle: state.scratchGui.connectionModal.isCommBle,
    version: state.scratchGui.fileManager.version,
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeConnectionModal());
    },
    onSetCommMode: isCommBle => {
        dispatch(setCommMode(isCommBle));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionModal);
