import PropTypes from 'prop-types';
import React from 'react';

import styles from './device-status-panel.css';

class DeviceStatusPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extensions: {},
            types: {},
            version: 0
        };
    }
    componentDidUpdate(prevProps) {
        let newitem = this.props.statusInfo;
        if (
            !newitem||Object.keys(newitem).length<=1
            || this.props.statusInfo.version === prevProps.statusInfo.version
            // || this.props.statusInfo.extensionId === prevProps.statusInfo.extensionId
            // && this.props.statusInfo.typeCode === prevProps.statusInfo.typeCode
            // && this.props.statusInfo.value === prevProps.statusInfo.value
        ) {
            return;
        }
        // //触发更新单条type
        // let type = {};
        // type[newitem.displayPriority] = newitem;
        // this.setState(() => (Object.assign(this.state.types, type)));

        // let extension={};
        // Object.values(newitem).map(e=>{
        //     extension[e.extensionName]={
        //         extensionId: e.extensionId,
        //         extensionName: e.extensionName
        //     }
        // })
        let first=Object.values(newitem).find(e=>typeof(e)==='object');
        const newExtension={};
        newExtension[first.extensionName]={
            extensionId: first.extensionId,
            extensionName: first.extensionName
        };
        this.setState(() => (Object.assign(this.state.extensions, newExtension)));
        //触发更新全部type
        this.setState(() => (Object.assign(this.state.types, newitem)));
    }
    render() {
        // const types = Object.values(this.state.types).map(t => {
        //     return <li>{t.extensionName}-{t.typeName}: {t.value}</li>
        // });
        // return (
        //     <div>
        //         <div>{this.state.version}</div>
        //         <ul class={styles.deviceStatusPanel}>{types}</ul>
        //     </div>
        // );

        const extensions = Object.values(this.state.extensions).map(e => {
            const types = Object.values(this.state.types).map(t => {
                if (t.extensionId===e.extensionId) {
                    if(t.isMultiValues===true){
                        const values=t.value.split(',').map(v=>{
                            return (<li>{v}</li>);
                        });
                        return (<li>
                            <div>{t.typeName}: </div>
                            <ul>{values}</ul>
                        </li>);
                    }
                    return (<li>{t.typeName}: {t.value}</li>);
                }
            });
            return (<li>
                <div>{e.extensionName}</div>
                <ul>{types}</ul>
            </li>);
        });

        return (
            <ul className={styles.deviceStatusPanel}>
                {extensions}
            </ul>
        );
    }
}

export default DeviceStatusPanel;