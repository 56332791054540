import classNames from 'classnames';
import {defineMessages, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import VM from 'scratch-vm';
import {BrowserView, isDesktop, isTablet} from 'react-device-detect';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import Controls from '../../containers/controls.jsx';
import {getStageDimensions} from '../../lib/screen-utils';
import {STAGE_SIZE_MODES, STAGE_SCREEN_MODES} from '../../lib/layout-constants';

import fullScreenIcon from './icon--fullscreen.svg';
import largeStageIcon from './icon--large-stage.svg';
import smallStageIcon from './icon--small-stage.svg';
import unFullScreenIcon from './icon--unfullscreen.svg';

import scratchLogo from '../menu-bar/scratch-logo.svg';
import styles from './stage-header.css';

const messages = defineMessages({
    largeStageSizeMessage: {
        defaultMessage: 'Switch to large stage',
        description: 'Button to change stage size to large',
        id: 'gui.stageHeader.stageSizeLarge'
    },
    smallStageSizeMessage: {
        defaultMessage: 'Switch to small stage',
        description: 'Button to change stage size to small',
        id: 'gui.stageHeader.stageSizeSmall'
    },
    fullStageSizeMessage: {
        defaultMessage: 'Enter full screen mode',
        description: 'Button to change stage size to full screen',
        id: 'gui.stageHeader.stageSizeFull'
    },
    unFullStageSizeMessage: {
        defaultMessage: 'Exit full screen mode',
        description: 'Button to get out of full screen mode',
        id: 'gui.stageHeader.stageSizeUnFull'
    },
    fullscreenControl: {
        defaultMessage: 'Full Screen Control',
        description: 'Button to enter/exit full screen mode',
        id: 'gui.stageHeader.fullscreenControl'
    }
});

const StageHeaderComponent = function (props) {
    const {
        isStageLeft,
        isFullScreen,
        isPlayerOnly,
        onKeyPress,
        onSetStageLarge,
        onSetStageSmall,
        onSetStageFull,
        onSetStageUnFull,
        onSetStageLeft,
        onSetStageSizeMode,
        showBranding,
        stageSizeMode,
        stageMode,
        vm
    } = props;

    let header = null;
    let stageSizeContent = null;
    let stageModeContent = null;
    if (isFullScreen) {
        const stageDimensions = getStageDimensions(null, stageMode, true);
        const stageButton = showBranding ? (
            <div className={styles.embedScratchLogo}>
                <a
                    href="http://www.td-robot.com"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Scratch"
                        src={scratchLogo}
                    />
                </a>
            </div>
        ) : (
            <Button
                className={styles.stageButton}
                onClick={onSetStageUnFull}
                onKeyPress={onKeyPress}
            >
                <img
                    alt={props.intl.formatMessage(messages.unFullStageSizeMessage)}
                    className={styles.stageButtonIcon}
                    draggable={false}
                    src={unFullScreenIcon}
                    title={props.intl.formatMessage(messages.fullscreenControl)}
                />
            </Button>
        );
        header = (
            <Box className={styles.stageHeaderWrapperOverlay}>
                <Box
                    className={styles.stageMenuWrapper}
                    style={{width: stageDimensions.width}}
                >
                    <Controls vm={vm} />
                    {stageButton}
                </Box>
            </Box>
        );
    } else {
        const stageModeControls =
            isPlayerOnly ? (
                []
            ) : (
                <div className={classNames(styles.stageSizeModeGroup)}>
                    <BrowserView>
                        <div
                            className={classNames(styles.stageSizeToggleGroup, styles.stageButton)}
                            onMouseEnter={() => {
                                stageModeContent ? stageModeContent.style = null : null;
                            }}
                        >
                            <div className={classNames(styles.stageButtonText,
                                {[styles.stageButtonText43]:stageMode === "4:3"},
                                {[styles.stageButtonText169]:stageMode === "16:9"},
                                {[styles.stageButtonText916]:stageMode === "9:16"}
                            )}>
                                {stageMode}
                            </div>
                        </div>
                        <div
                            className={classNames(styles.stageModeContent)}
                            ref={ref => {
                                stageModeContent = ref;
                            }}
                        >
                            <div>
                                <Button
                                    className={classNames(
                                        styles.stageButton,
                                        styles.stageButtonTop,
                                        (stageMode === "4:3") ? null : styles.stageButtonToggledOff
                                    )}
                                    onClick={() =>{
                                        onSetStageSizeMode("4:3");
                                        stageModeContent ? stageModeContent.style = 'display:none' : null;
                                    }}
                                >
                                    <div className={classNames(styles.stageButtonText,styles.stageButtonText43)}>
                                        {"4:3"}
                                    </div>
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className={classNames(
                                        styles.stageButton,
                                        styles.stageButtonMiddle,
                                        (stageMode === "16:9") ? null : styles.stageButtonToggledOff
                                    )}
                                    onClick={()=>{
                                        onSetStageSizeMode("16:9");
                                        stageModeContent ? stageModeContent.style = 'display:none' : null;}
                                    }
                                >
                                    <div className={classNames(styles.stageButtonText,styles.stageButtonText169)}>
                                        {"16:9"}
                                    </div>
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className={classNames(
                                        styles.stageButton,
                                        styles.stageButtonBottom,
                                        (stageMode === "9:16") ? null : styles.stageButtonToggledOff
                                    )}
                                    onClick={()=>{
                                        onSetStageSizeMode("9:16");
                                        stageModeContent ? stageModeContent.style = 'display:none' : null;}
                                    }
                                >
                                    <div className={classNames(styles.stageButtonText,styles.stageButtonText916)}>
                                        {"9:16"}
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </BrowserView>
                </div>
            );
        const stageControls =
            isPlayerOnly ? (
                []
            ) : (
                <div className={classNames(styles.stageSizeModeGroup)}>
                    <div
                        className={classNames(styles.stageSizeToggleGroup, styles.stageButton)}
                        onMouseEnter={() => {
                            stageSizeContent ? stageSizeContent.style = null : null;
                        }}
                    >
                        <img
                            alt={props.intl.formatMessage(stageSizeMode === STAGE_SIZE_MODES.large?
                                messages.largeStageSizeMessage:messages.smallStageSizeMessage)}
                            className={classNames(styles.stageButtonIcon, {[styles.stageButtonIconLeft]:isStageLeft})}
                            draggable={false}
                            src={stageSizeMode === STAGE_SIZE_MODES.large?largeStageIcon:smallStageIcon}
                        />
                    </div>
                    <div
                        className={classNames(styles.stageModeContent)}
                        ref={ref => {
                            stageSizeContent = ref;
                        }}
                    >
                        <div>
                            <Button
                                className={classNames(
                                    styles.stageButton,
                                    styles.stageButtonTop,
                                    (stageSizeMode === STAGE_SIZE_MODES.small && !isStageLeft) ? null : styles.stageButtonToggledOff
                                )}
                                onClick={() =>{
                                    onSetStageSmall();
                                    onSetStageLeft(false);
                                    stageSizeContent ? stageSizeContent.style = 'display:none' : null;
                                }}
                            >
                                <img
                                    alt={props.intl.formatMessage(messages.smallStageSizeMessage)}
                                    className={styles.stageButtonIcon}
                                    draggable={false}
                                    src={smallStageIcon}
                                />
                            </Button>
                        </div>
                        {(isDesktop || isTablet) && <div>
                            <Button
                                className={classNames(
                                    styles.stageButton,
                                    styles.stageButtonMiddle,
                                    (stageSizeMode === STAGE_SIZE_MODES.large && !isStageLeft) ? null : styles.stageButtonToggledOff
                                )}
                                onClick={()=>{
                                    onSetStageLarge(); 
                                    onSetStageLeft(false);
                                    stageSizeContent ? stageSizeContent.style = 'display:none' : null;}
                                }
                            >
                                <img
                                    alt={props.intl.formatMessage(messages.largeStageSizeMessage)}
                                    className={styles.stageButtonIcon}
                                    draggable={false}
                                    src={largeStageIcon}
                                />
                            </Button>
                        </div>}
                        <div>
                            <Button
                                className={classNames(
                                    styles.stageButton,
                                    styles.stageButtonMiddle,
                                    (stageSizeMode === STAGE_SIZE_MODES.small && isStageLeft) ? null : styles.stageButtonToggledOff
                                )}
                                onClick={() =>{
                                    onSetStageSmall();
                                    onSetStageLeft(true);
                                    stageSizeContent ? stageSizeContent.style = 'display:none' : null;
                                }}
                            >
                                <img
                                    alt={props.intl.formatMessage(messages.smallStageSizeMessage)}
                                    className={classNames(styles.stageButtonIcon, styles.stageButtonIconLeft)}
                                    draggable={false}
                                    src={smallStageIcon}
                                />
                            </Button>
                        </div>
                        {(isDesktop || isTablet) && <div>
                            <Button
                                className={classNames(
                                    styles.stageButton,
                                    styles.stageButtonBottom,
                                    (stageSizeMode === STAGE_SIZE_MODES.large && isStageLeft) ? null : styles.stageButtonToggledOff
                                )}
                                onClick={()=>{
                                    onSetStageLarge(); 
                                    onSetStageLeft(true);
                                    stageSizeContent ? stageSizeContent.style = 'display:none' : null;}
                                }
                            >
                                <img
                                    alt={props.intl.formatMessage(messages.largeStageSizeMessage)}
                                    className={classNames(styles.stageButtonIcon, styles.stageButtonIconLeft)}
                                    draggable={false}
                                    src={largeStageIcon}
                                />
                            </Button>
                        </div>}
                    </div>
                </div>
            );
        header = (
            <Box className={styles.stageHeaderWrapper}>
                <Box className={styles.stageMenuWrapper}>
                    <div />
                    {/* <Controls vm={vm}  /> */}
                    <div className={styles.stageSizeRow}>
                        {stageModeControls}
                        {stageControls}
                        <div>
                            <Button
                                className={styles.stageButton}
                                onClick={onSetStageFull}
                            >
                                <img
                                    alt={props.intl.formatMessage(messages.fullStageSizeMessage)}
                                    className={styles.stageButtonIcon}
                                    draggable={false}
                                    src={fullScreenIcon}
                                    title={props.intl.formatMessage(messages.fullscreenControl)}
                                />
                            </Button>
                        </div>
                    </div>
                </Box>
            </Box>
        );
    }

    return header;
};

const mapStateToProps = state => ({
    // This is the button's mode, as opposed to the actual current state
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    stageMode: state.scratchGui.stageSize.stageMode,
    isStageLeft: state.scratchGui.mode.isStageLeft,
});

StageHeaderComponent.propTypes = {
    intl: intlShape,
    isFullScreen: PropTypes.bool.isRequired,
    isPlayerOnly: PropTypes.bool.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    onSetStageFull: PropTypes.func.isRequired,
    onSetStageLarge: PropTypes.func.isRequired,
    onSetStageSmall: PropTypes.func.isRequired,
    onSetStageUnFull: PropTypes.func.isRequired,
    showBranding: PropTypes.bool.isRequired,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    stageMode: PropTypes.oneOf(Object.keys(STAGE_SCREEN_MODES)),
    vm: PropTypes.instanceOf(VM).isRequired
};

StageHeaderComponent.defaultProps = {
    stageSizeMode: STAGE_SIZE_MODES.large
};

export default injectIntl(connect(
    mapStateToProps
)(StageHeaderComponent));
