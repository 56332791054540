exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #3692f8 */ /* #e9f2ff */ /* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .file-manager_modal-content_1aWS7 {\r\n    height: 390px;\r\n    width: 550px;\r\n} .file-manager_modal-newfolder_K8NVI div[aria-hidden=\"true\"] {\r\n    display: none !important;\r\n} .file-manager_path-link_c7LS2 {\r\n    /* 将鼠标指针改为手指形状，表明这是可点击的链接 */\r\n    cursor: pointer;\r\n    /* 文本颜色与下划线相同 */\r\n    color: #00f;\r\n    /* 移除默认的下划线 */\r\n    text-decoration: none;\r\n} .ant-layout-header {\r\n    padding: 0 10px;\r\n    height: 50px;\r\n    line-height: 50px;\r\n} .ant-table-thead>tr>th {\r\n    padding: 10px;\r\n} .ant-table-tbody>tr>td {\r\n    padding: 6px;\r\n} .file-manager_progress-area_2eUe3 {\r\n    position: absolute;\r\n    width: 550px;\r\n    height: 340px;\r\n    top: 50px;\r\n    left: 0;\r\n    background-color: hsla(221, 34%, 13%, 0.5);\r\n    z-index: 1;\r\n} .ant-progress-circle .ant-progress-text {\r\n    color: white;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"modal-content": "file-manager_modal-content_1aWS7",
	"modalContent": "file-manager_modal-content_1aWS7",
	"modal-newfolder": "file-manager_modal-newfolder_K8NVI",
	"modalNewfolder": "file-manager_modal-newfolder_K8NVI",
	"path-link": "file-manager_path-link_c7LS2",
	"pathLink": "file-manager_path-link_c7LS2",
	"progress-area": "file-manager_progress-area_2eUe3",
	"progressArea": "file-manager_progress-area_2eUe3"
};