import styles from '../containers/blocks.css';
/**
 * 积木块垃圾桶
 */
export default function (Blockly){

    const oldStartDrag = Blockly.BlockDragger.prototype.startBlockDrag;
    Blockly.BlockDragger.prototype.startBlockDrag = function () {
        const deleteArea = document.getElementById('deleteBlocksTip');
        deleteArea.style.display = 'flex';
        deleteArea.style.background = null;
        return (oldStartDrag.apply(this, arguments));
    };

    const oldEndBlockDrag = Blockly.BlockDragger.prototype.endBlockDrag;
    Blockly.BlockDragger.prototype.endBlockDrag = function () {
        document.getElementById('deleteBlocksTip').style.display = 'none';
        return (oldEndBlockDrag.apply(this, arguments));
    };

    
    const OldUpdateCursorDuringBlockDrag_ = Blockly.BlockDragger.prototype.updateCursorDuringBlockDrag_;
    Blockly.BlockDragger.prototype.updateCursorDuringBlockDrag_ = function (isOutside){
        OldUpdateCursorDuringBlockDrag_.apply(this, arguments);
        const deleteArea = document.getElementById('deleteBlocksTip');
        if(this.deleteArea_ == Blockly.DELETE_AREA_TOOLBOX){
            deleteArea.style.background = 'rgba(252, 3, 3, 0.6)';
        }else{
            deleteArea.style.background = 'rgba(0, 0, 0, 0.6)';
        }
    }
}