import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';
import React, { useState } from 'react';
import { isDesktop } from 'react-device-detect';

import VM from 'scratch-vm';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import { ComingSoonTooltip } from '../coming-soon/coming-soon.jsx';
import SBFileUploader from '../../containers/sb-file-uploader.jsx';
// import MenuBarMenu from './menu-bar-menu.jsx';
// import { MenuItem, MenuSection } from '../menu/menu.jsx';
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';

import { openExtensionSelector, openFileManager, openTipsLibrary, openPreferences } from '../../reducers/modals';
import { setPlayer } from '../../reducers/mode';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    openToolsMenu,
    closeToolsMenu,
    toolsMenuOpen
} from '../../reducers/menus';

import collectMetadata from '../../lib/collect-metadata';

// import styles from './menu-bar.css';

import remixIcon from './icon--remix.svg';

import scratchLogo from './scratch-logo.svg';

import sharedMessages from '../../lib/shared-messages';
// import moreIcon from './icon--more.svg';
import moreIcon from './icon--more.png';

import iconStage from './icon/icon_stage.png';
import iconNew from './icon/icon_new.png';
import iconLoad from './icon/icon_load.png';
import iconSave from './icon/icon_save.png';
import iconTurbo from './icon/icon_turbo.png';
import iconDevicesList from './icon/icon_devices_list.png';
// import iconInfrared from './icon/icon_infrared.png';
import iconDevicesValue from './icon/icon_devices_value.png';
import iconPrefs from './icon/icon_prefs.png';
import iconNetCfg from './icon/network_config.png';
import iconMngFile from './icon/manager_file.png';
import iconLanguage from '../language-selector/language-icon-black.svg';

import { Alert, Button as AButton, Icon, Input, List, message, Modal, Table } from 'antd';

import {
    activateDeck
} from '../../reducers/cards';

import { Menu, MenuItem, MenuButton, MenuDivider, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import styles from './menu-bar-new.css';

import { selectLocale } from '../../reducers/locales';

import { extensionSelectorAfterSelecting } from '../../reducers/extension-selector';

import {preferencesModalGetPreferences} from '../../reducers/preferences-modal.js';

// const ariaMessages = defineMessages({
//     language: {
//         id: 'gui.menuBar.LanguageSelector',
//         defaultMessage: 'language selector',
//         description: 'accessibility text for the language selection menu'
//     },
//     tutorials: {
//         id: 'gui.menuBar.tutorialsLibrary',
//         defaultMessage: 'Tutorials',
//         description: 'accessibility text for the tutorials button'
//     }
// });

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};

MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({ id, isRtl, children, className }) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleChangeLang',
            'handleChangePasswd',
            'handleChangeSsid',
            'handleChooseExtension',
            'handleChooseExtensionCancel',
            'handleClickCloneInfraredSignal',
            'handleClickFileManage',
            'handleClickModuleList',
            'handleClickNetworkConfig',
            'handleClickNew',
            'handleClickPreferences',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleCloseChooseExtensionAlert',
            'handleGetNetworkStatus',
            'handleGetNetworkStatusCancel',
            'handleKeyPress',
            'handleLanguageMouseUp',
            'handleNetworkConfig',
            'handleNetworkConfigCancel',
            'handleNetworkConfigExecute',
            'handleRestoreOption',
            'handleToggleStageHidden',
            'getSaveToComputerHandler',
            'loadExtensionWhichSupportNetworkConfig',
            'restoreOptionMessage',
            'setPasswd',
            'setSsid'
        ]);
        this.state = {
            buttonTitleConnect: '连接',
            buttonTypeConnect: 'primary',
            chosenExtensionId: '',
            /**
             * 是否禁止配网
             */
            disabledNetworkConfig: false,
            /**
             * 是否正在处理中
             */
            disabledProccessing: false,
            /**
             * 选择完扩展后的事件 0-未知，1-配网，2-管理文件
             */
            eventChooseExtension: 0,
            extensions: [],
            fileResourceColumns: [
                {
                    title: '编号',
                    dataIndex: 'id',
                    key: 'id',
                    width: 50,
                },
                {
                    title: '目录路径',
                    dataIndex: 'path',
                    key: 'path',
                    width: 100,
                },
            ],
            fileResourceItems: [],
            messageChooseExtension: '',
            messageNetworkConfig: '连接成功',
            /**
             * WIFI密码
             */
            passwd: '',
            /**
             * WIFI名称
             */
            ssid: '',
            typeChooseExtension: 'warning',
            typeNetworkConfig: 'success',
            /**
             * 是否隐藏选择扩展的提醒框
             */
            visibleChooseExtensionAlert: false,
            /**
             * 是否隐藏选择扩展的对话框
             */
            visibleChooseExtensionModal: false,
            /**
             * 是否隐藏配置网络的提醒框
             */
            visibleNetworkConfigAlert: false,
            /**
             * 是否隐藏配置网络的对话框
             */
            visibleNetworkConfigModal: false,
            /**
             * 是否隐藏获取网络状态的对话框
             */
            visibleGetNetworkStatus: false
        };
    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    handleChangeLang(newLocale) {
        this.props.onChangeLanguage(newLocale);
        document.documentElement.lang = newLocale;
    }
    handleChangePasswd(e) {
        this.setPasswd(e.target.value);
    }
    handleChangeSsid(e) {
        this.setSsid(e.target.value);
    }
    handleChooseExtension(id) {
        if (!Object.keys(this.props.vm.runtime.peripheralExtensions).includes(id)
            || !this.props.vm.runtime.peripheralExtensions[id].isConnected()
        ) {
            this.setState({
                messageChooseExtension: window.GetLocaleMsgs(document.documentElement.lang)['messageChooseExtensionNotConnected'],
                typeChooseExtension: 'error',
                visibleChooseExtensionAlert: true
            })
            return;
        }
        // console.log(id);
        this.setState({
            chosenExtensionId: id,
            visibleChooseExtensionModal: false
        });
        switch (this.state.eventChooseExtension) {
            case 1:
                this.handleGetNetworkStatus(id);
                // this.handleNetworkConfig(id, false); //测试时强制跳转
                break;
        }
    }
    handleChooseExtensionCancel(e) {
        this.setState({
            visibleChooseExtensionModal: false
        });
    }
    handleClickFileManage() {
        this.props.onRequestCloseFile();
        this.props.onOpenFileManager();
    }
    handleClickNetworkConfig() {
        this.props.onRequestCloseFile();
        this.setState({
            eventChooseExtension: 1
        });
        this.loadExtensionWhichSupportNetworkConfig();
    }
    handleClickNew() {
        // if the project is dirty, and user owns the project, we will autosave.
        // but if they are not logged in and can't save, user should consider
        // downloading or logging in first.
        // Note that if user is logged in and editing someone else's project,
        // they'll lose their work.
        const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
            this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
        );
        this.props.onRequestCloseFile();
        if (readyToReplaceProject) {
            this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
        }
        this.props.onRequestCloseFile();
    }
    handleToggleStageHidden() {
        this.props.toggleStageHidden();
        this.props.onRequestCloseFile();
    }
    handleClickRemix() {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave() {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy() {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity(waitForUpdate) {
        if (this.props.shouldSaveBeforeTransition()) {
            this.props.autoUpdateProject(); // save before transitioning to project page
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare(waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleClickModuleList() {
        this.props.onRequestCloseFile();
        // window.ManageDeviceMain();
        this.props.onSelectExtension(2);
    }
    handleClickCloneInfraredSignal() {
        this.props.onRequestCloseFile();
        window.CloneInfraredSignalMain();
    }
    handleClickPreferences() {
        this.props.onRequestCloseFile();
        // window.PreferencesMain();
        this.props.onOpenPreferences();
    }
    handleCloseChooseExtensionAlert(e) {
        this.setState({
            visibleChooseExtensionAlert: false
        })
    }
    handleGetNetworkStatus(id) {
        let ext = this.props.vm.runtime.peripheralExtensions[id];
        if (!ext || !ext.getNetworkStatus) {
            console.error('Extension error: can not find getNetworkStatus() method!');
            return;
        }
        this.setState({
            visibleGetNetworkStatus: true
        });
        let count = 0;
        const maxTry = 10;
        ext.networkConfigCommunicate.getStatus = 0;
        let t = this;
        let flagReq = setInterval(() => {
            if (ext.networkConfigCommunicate.getStatus === 0 && count < maxTry) {
                ext.getNetworkStatus();
                count += 1;
            } else {
                const msgs = window.GetLocaleMsgs(document.documentElement.lang);
                clearInterval(flagReq);
                // console.log(ext.networkConfigStatus);
                if (ext.networkConfigCommunicate.getStatus === 0) {
                    t.setState({
                        visibleGetNetworkStatus: false
                    });
                    message.warning(msgs['messageNetworkConfigNoResponse']);
                    return;
                }
                //判断最终网络状态
                let code = ext.networkConfigStatus.resultCode;
                let description = '';
                let type = 'success';
                switch (code) {
                    case 200:
                        description = msgs['messageNetworkConfigStatusCode200'];
                        type = 'error';
                        break;
                    case 201:
                        description = msgs['messageNetworkConfigStatusCode201'];
                        type = 'error';
                        break;
                    case 202:
                        description = msgs['messageNetworkConfigStatusCode202'];
                        type = 'error';
                        break;
                    case 1000:
                        description = msgs['messageNetworkConfigStatusCode1000'];
                        type = 'info';
                        break;
                    case 1001:
                        description = msgs['messageNetworkConfigStatusCode1001'];
                        type = 'info';
                        break;
                    case 1010:
                        description = msgs['messageNetworkConfigStatusCode1010'];
                        type = 'success';
                        break;
                    default:
                        if (code < 1000) {
                            description = msgs['messageNetworkConfigStatusCodeUnknownError'];
                            type = 'error';
                        } else {
                            description = msgs['messageNetworkConfigStatusCodeUnknownStatus'];
                            type = 'info';
                        }
                        break;
                }
                t.setState({
                    messageNetworkConfig: `${msgs['messageNetworkConfigStatus']}-${ext.networkConfigStatus.resultCode}: ${description}`,
                    typeNetworkConfig: type,
                    visibleGetNetworkStatus: false,
                    visibleNetworkConfigAlert: true
                });
                t.handleNetworkConfig(id, ext.networkConfigStatus.resultCode === 1010);
            }
        }, 3000);
    }
    handleGetNetworkStatusCancel() {
        this.setState({
            visibleGetNetworkStatus: false
        });
    }
    handleNetworkConfig(id, isConnected) {
        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        this.setState({
            buttonTitleConnect: isConnected === true ? msgs['messageNetworkConfigDisconnect'] : msgs['messageNetworkConfigConnect'],
            buttonTypeConnect: isConnected === true ? 'danger' : 'primary',
            disabledNetworkConfig: isConnected === true,
            disabledProccessing: false,
            visibleNetworkConfigModal: true
        });
        // console.log(id);
    }
    handleNetworkConfigCancel() {
        this.setState({
            visibleNetworkConfigModal: false
        });
    }
    handleNetworkConfigExecute() {
        let ext = this.props.vm.runtime.peripheralExtensions[this.state.chosenExtensionId];
        if (!ext) {
            console.error('Get extension error!');
            return;
        }
        let count = 0;
        const maxTry = 10;
        let t = this;
        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        if (this.state.disabledNetworkConfig === false) {
            // console.log(`Send ssid=${this.state.ssid} password=${this.state.passwd}`);
            //调用VM扩展里的发送配网请求
            ext.networkConfigCommunicate.connect = 0;
            t.setState({
                disabledProccessing: true
            });
            let flagOpen = setInterval(() => {
                if (ext.networkConfigCommunicate.connect === 0 && count < maxTry) {
                    ext.openNetworkConnection(this.state.ssid, this.state.passwd);
                    count += 1;
                } else {
                    clearInterval(flagOpen);
                    if (ext.networkConfigCommunicate.connect === 0) {
                        t.setState({
                            disabledProccessing: false,
                            messageNetworkConfig: msgs['messageNetworkConfigNoResponse'],
                            typeNetworkConfig: 'error',
                            visibleNetworkConfigAlert: true
                        });
                        return;
                    }
                    t.setState({
                        // disabledProccessing: false,
                        messageNetworkConfig: msgs['messageNetworkConfigWaitAfterSentConnect'],
                        typeNetworkConfig: 'success',
                        visibleNetworkConfigAlert: true
                    });
                    setTimeout(() => {
                        t.setState({
                            visibleNetworkConfigAlert: false,
                            visibleNetworkConfigModal: false
                        });
                        t.handleGetNetworkStatus(t.state.chosenExtensionId);
                    }, 3000);
                }
            }, 3000);
        } else {
            //调用VM扩展里的关闭连接请求
            ext.networkConfigCommunicate.disconnect = 0;
            t.setState({
                disabledProccessing: true
            });
            let flagClose = setInterval(() => {
                if (ext.networkConfigCommunicate.disconnect === 0 && count < maxTry) {
                    ext.closeNetworkConnection();
                    count += 1;
                } else {
                    clearInterval(flagClose);
                    if (ext.networkConfigCommunicate.disconnect === 0) {
                        t.setState({
                            disabledProccessing: false,
                            messageNetworkConfig: msgs['messageNetworkConfigNoResponse'],
                            typeNetworkConfig: 'error',
                            visibleNetworkConfigAlert: true
                        });
                        return;
                    }
                    t.setState({
                        // disabledProccessing: false,
                        messageNetworkConfig: msgs['messageNetworkConfigWaitAfterSentDisconnect'],
                        typeNetworkConfig: 'success',
                        visibleNetworkConfigAlert: true
                    });
                    setTimeout(() => {
                        t.setState({
                            visibleNetworkConfigAlert: false,
                            visibleNetworkConfigModal: false
                        });
                        t.handleGetNetworkStatus(t.state.chosenExtensionId);
                    }, 3000);
                }
            }, 3000);
        }
    }
    handleRestoreOption(restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress(event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === 's') {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    getSaveToComputerHandler(downloadProjectCallback) {
        return () => {
            this.props.onRequestCloseFile();
            downloadProjectCallback();
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
                this.props.onProjectTelemetryEvent('projectDidSave', metadata);
            }
        };
    }
    handleLanguageMouseUp(e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    loadExtensionWhichSupportNetworkConfig() {
        let _extensions = [];
        const msgs = window.GetLocaleMsgs(document.documentElement.lang);
        let exts = this.props.vm.runtime.peripheralExtensions;
        if (!exts || Object.keys(exts).length == 0) {
            message.warning(msgs['messageExtensionMustSupportNetworkConfig']);
            return;
        }
        for (let key in exts) {
            let ext = exts[key];
            if (ext.isSupportNetworkConfig && ext.isSupportNetworkConfig === true) {
                _extensions.push({
                    extensionId: key,
                    extensionName: msgs["extensions"][key].title
                });
            }
        }
        this.setState({
            extensions: _extensions,
            visibleChooseExtensionModal: true
        })
    }
    restoreOptionMessage(deletedItem) {
        switch (deletedItem) {
            case 'Sprite':
                return (<FormattedMessage
                    defaultMessage="Restore Sprite"
                    description="Menu bar item for restoring the last deleted sprite."
                    id="gui.menuBar.restoreSprite"
                />);
            case 'Sound':
                return (<FormattedMessage
                    defaultMessage="Restore Sound"
                    description="Menu bar item for restoring the last deleted sound."
                    id="gui.menuBar.restoreSound"
                />);
            case 'Costume':
                return (<FormattedMessage
                    defaultMessage="Restore Costume"
                    description="Menu bar item for restoring the last deleted costume."
                    id="gui.menuBar.restoreCostume"
                />);
            default: {
                return (<FormattedMessage
                    defaultMessage="Restore"
                    description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                    id="gui.menuBar.restore"
                />);
            }
        }
    }
    setPasswd(value) {
        this.setState({
            passwd: value
        })
    }
    setSsid(value) {
        this.setState({
            ssid: value
        })
    }
    render() {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.moreItem
                )}
            >
                <div className={styles.mainMenu}>
                    <div className={styles.fileGroup}>
                        <div
                            className={classNames(styles.moreItemContainer, styles.hoverable, {
                                [styles.active]: this.props.fileMenuOpen
                            })}
                            onMouseUp={this.props.onClickFile}
                        >
                            <Menu
                                menuButton={<MenuButton
                                    className={styles.szhMenuButton}
                                ><img className={styles.moreButton} src={moreIcon} /></MenuButton>}
                            >
                                <MenuItem
                                    className={styles.szhMenuItem}
                                    onClick={this.handleToggleStageHidden}
                                >
                                    <img src={iconStage} className={styles.menuItemIcon} />
                                    {this.props.isStageHidden ? <FormattedMessage
                                        id="gui.menuBar.showStage"
                                        defaultMessage="Show Stage"
                                    /> : <FormattedMessage
                                        id="gui.menuBar.hideStage"
                                        defaultMessage="Hide Stage"
                                    />}
                                </MenuItem>

                                {/* 新版设备状态值 */}
                                <MenuItem
                                    onClick={() => {
                                        this.props.onActivateDeck('deviceStatus');
                                    }}
                                    className={styles.szhMenuItem}
                                >
                                    <img src={iconDevicesValue} className={styles.menuItemIcon} />
                                    <FormattedMessage
                                        defaultMessage="Device status"
                                        description="Link for opening a device status dialog"
                                        id="gui.menuBar.deviceStatus"
                                    />
                                </MenuItem>

                                {isDesktop && <TurboMode>{(toggleTurboMode, { turboMode }) => (
                                    <MenuItem onClick={toggleTurboMode}
                                        className={styles.szhMenuItem}
                                    >
                                        <img src={iconTurbo} className={styles.menuItemIcon} />
                                        {turboMode ? (
                                            <FormattedMessage
                                                defaultMessage="Turn off Turbo Mode"
                                                description="Menu bar item for turning off turbo mode"
                                                id="gui.menuBar.turboModeOff"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                defaultMessage="Turn on Turbo Mode"
                                                description="Menu bar item for turning on turbo mode"
                                                id="gui.menuBar.turboModeOn"
                                            />
                                        )}
                                    </MenuItem>
                                )}</TurboMode>}

                                <MenuItem
                                    onClick={this.handleClickModuleList}
                                    className={styles.szhMenuItem}
                                >
                                    <img src={iconDevicesList} className={styles.menuItemIcon} />
                                    {/* 外接模块列表 */}
                                    <FormattedMessage
                                        defaultMessage="Module List"
                                        id="gui.menuBar.moduleList"
                                    />
                                </MenuItem>
                                {/* 红外信号管理器 */}
                                {/* <MenuItem
                                    onClick={this.handleClickCloneInfraredSignal}
                                    className={styles.szhMenuItem}
                                >
                                    <img src={iconInfrared} className={styles.menuItemIcon} />
                                    
                                    <FormattedMessage
                                        defaultMessage="Clone infrared signal"
                                        description="Link for opening a clone infrared signal dialog"
                                        id="gui.menuBar.cloneInfraredSignal"
                                    />
                                </MenuItem> */}
                                <MenuItem
                                    onClick={this.handleClickNetworkConfig}
                                    className={styles.szhMenuItem}
                                >
                                    <img src={iconNetCfg} className={styles.menuItemIcon} />
                                    <FormattedMessage
                                        defaultMessage="Network Configuration"
                                        id="gui.menuBar.networkConfig"
                                    />
                                </MenuItem>

                                {/* 首选项 */}
                                {
                                //navigator.userAgent.toLowerCase().indexOf(' electron/') > -1 &&
                                <MenuItem
                                    onClick={this.handleClickPreferences}
                                    className={styles.szhMenuItem}
                                >
                                    <img src={iconPrefs} className={styles.menuItemIcon} />
                                    <FormattedMessage
                                        defaultMessage="Preferences"
                                        description="Link for opening a preferences dialog"
                                        id="gui.menuBar.preferences"
                                    />
                                </MenuItem>}

                                <MenuItem
                                    onClick={this.handleClickFileManage}
                                    className={styles.szhMenuItem}
                                >
                                    <img src={iconMngFile} className={styles.menuItemIcon} />
                                    <FormattedMessage
                                        defaultMessage="Manage Files on Device"
                                        id="gui.menuBar.manageFile"
                                    />
                                </MenuItem>

                                {isDesktop && (this.props.canManageFiles) && (
                                    <MenuItem
                                        onClick={this.handleClickNew}
                                        className={styles.szhMenuItem}
                                    >
                                        <img src={iconNew} className={styles.menuItemIcon} />
                                        {newProjectMessage}
                                    </MenuItem>
                                )}
                                {(this.props.canManageFiles) && (
                                    (this.props.canSave) && (
                                        <MenuItem onClick={this.handleClickSave}>
                                            {saveNowMessage}
                                        </MenuItem>
                                    )
                                        (this.props.canCreateCopy) && (
                                            <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                {createCopyMessage}
                                            </MenuItem>
                                        )
                                        (this.props.canRemix) && (
                                        <MenuItem onClick={this.handleClickRemix}>
                                            {remixMessage}
                                        </MenuItem>
                                    )
                                )}
                                {(this.props.canManageFiles) && (
                                    <SBFileUploader
                                        canSave={this.props.canSave}
                                        userOwnsProject={this.props.userOwnsProject}
                                    >
                                        {(className, renderFileInput, handleLoadProject) => (
                                            <MenuItem
                                                className={styles.szhMenuItem}
                                                onClick={handleLoadProject}
                                                iconPath={iconLoad}
                                            >
                                                <img src={iconLoad} className={styles.menuItemIcon} />
                                                {/* eslint-disable max-len */}
                                                {this.props.intl.formatMessage(sharedMessages.loadFromComputerTitle)}
                                                {/* eslint-enable max-len */}
                                                {renderFileInput()}
                                            </MenuItem>
                                        )}
                                    </SBFileUploader>
                                )}
                                {(this.props.canManageFiles) && (
                                    <SB3Downloader>{(className, downloadProjectCallback) => (
                                        <MenuItem
                                            className={styles.szhMenuItem}
                                            onClick={this.getSaveToComputerHandler(downloadProjectCallback)}
                                        >
                                            <img src={iconSave} className={styles.menuItemIcon} />
                                            <FormattedMessage
                                                defaultMessage="Save to your computer"
                                                description="Menu bar item for downloading a project to your computer" // eslint-disable-line max-len
                                                id="gui.menuBar.downloadToComputer"
                                            />
                                        </MenuItem>
                                    )}</SB3Downloader>
                                )}
                                <MenuDivider className={styles.szhMenuDivider} />
                                <SubMenu label={() => (<div>
                                    <img src={iconLanguage} className={styles.menuItemIcon} />
                                    <span>Language</span>
                                    <span>&gt;</span>
                                </div>)}
                                    className={styles.szhMenuItem}
                                >
                                    <MenuItem className={styles.szhMenuItem} onClick={() => { this.handleChangeLang('en') }}>English</MenuItem>
                                    <MenuItem className={styles.szhMenuItem} onClick={() => { this.handleChangeLang('zh-cn') }}>简体中文</MenuItem>
                                    <MenuItem className={styles.szhMenuItem} onClick={() => { this.handleChangeLang('uk') }}>Українська</MenuItem>
                                    <MenuItem className={styles.szhMenuItem} onClick={() => { this.handleChangeLang('ja') }}>日本語</MenuItem>
                                </SubMenu>
                            </Menu>
                        </div>
                    </div>
                </div>

                <Modal
                    title={<FormattedMessage
                        id="gui.menuBar.modal.chooseExtension.title"
                        defaultMessage="Choose an extension"
                    />}
                    visible={this.state.visibleChooseExtensionModal}
                    onCancel={this.handleChooseExtensionCancel}
                    footer={null}
                >
                    <List
                        bordered
                        dataSource={this.state.extensions}
                        renderItem={item => (
                            <List.Item>
                                <AButton onClick={(e) => {
                                    if (e) {
                                        e.preventDefault();
                                    }
                                    this.handleChooseExtension(item.extensionId)
                                }}>{item.extensionName}</AButton>
                            </List.Item>
                        )}
                    />
                    {this.state.visibleChooseExtensionAlert ? (
                        <Alert
                            message={this.state.messageChooseExtension}
                            type={this.state.typeChooseExtension}
                            closable
                            onClose={this.handleCloseChooseExtensionAlert}
                        />
                    ) : null}
                </Modal>

                <Modal
                    title={<FormattedMessage
                        id="gui.menuBar.modal.getNetworkStatus.title"
                        defaultMessage="Info"
                    />}
                    visible={this.state.visibleGetNetworkStatus}
                    onCancel={this.handleGetNetworkStatusCancel}
                    footer={null}
                >
                    <h4><FormattedMessage
                        id="gui.menuBar.modal.getNetworkStatus.content"
                        defaultMessage="Getting network status, please wait……"
                    /></h4>
                </Modal>

                <Modal
                    title={<FormattedMessage
                        id="gui.menuBar.networkConfig"
                        defaultMessage="Network Configuration"
                    />}
                    visible={this.state.visibleNetworkConfigModal}
                    onCancel={this.handleNetworkConfigCancel}
                    footer={<div style={{ textAlign: 'center' }}>
                        <AButton type={this.state.buttonTypeConnect} disabled={this.state.disabledProccessing} onClick={(e) => {
                            if (e) {
                                e.preventDefault();
                            }
                            this.handleNetworkConfigExecute();
                        }}>
                            {this.state.buttonTitleConnect}
                        </AButton>
                    </div>}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Input addonBefore={<FormattedMessage
                            id="gui.menuBar.modal.networkConfig.ssid"
                            defaultMessage="Wi-Fi SSID: "
                        />} disabled={this.state.disabledNetworkConfig} value={this.state.ssid} maxLength="32" onChange={this.handleChangeSsid} />
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <Input.Password addonBefore={<FormattedMessage
                            id="gui.menuBar.modal.networkConfig.passwd"
                            defaultMessage="Wi-Fi Password: "
                        />} disabled={this.state.disabledNetworkConfig} value={this.state.passwd} maxLength="64" onChange={this.handleChangePasswd} />
                    </div>
                    {this.state.visibleNetworkConfigAlert ? (
                        <Alert
                            message={this.state.messageNetworkConfig}
                            type={this.state.typeNetworkConfig}
                            closable
                        />
                    ) : null}
                </Modal>
            </Box>
        );
    }
}

MenuBar.propTypes = {
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    toolsMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onClickTools: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    userOwnsProject: PropTypes.bool,
    username: PropTypes.string,
    isStageHidden: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    toggleStageHidden: PropTypes.func,
    toolsMenuOpen: PropTypes.bool
};

MenuBar.defaultProps = {
    logo: scratchLogo,
    onShare: () => { }
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        username: user ? user.username : null,
        userOwnsProject: ownProps.authorUsername && user &&
            (ownProps.authorUsername === user.username),
        vm: state.scratchGui.vm,
        toolsMenuOpen: toolsMenuOpen(state),
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onClickTools: () => dispatch(openToolsMenu()),
    onRequestCloseTools: () => dispatch(closeToolsMenu())

    , onActivateDeck: (activeDeckId) => {
        dispatch(activateDeck(activeDeckId));
    },

    onOpenFileManager: () => dispatch(openFileManager()),
    onChangeLanguage: locale => {
        dispatch(selectLocale(locale));
    },
    onSelectExtension: (actionNum)=>{
        dispatch(extensionSelectorAfterSelecting(actionNum));
        dispatch(openExtensionSelector());
    },
    onOpenPreferences: ()=>{
        dispatch(preferencesModalGetPreferences());
        dispatch(openPreferences());
    }
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuBar);
